<template>
	<div class="events">
		<main class="events__main">
			<div class="events__content">
				<h2 class="events__headline">Мероприятия</h2>
				<div class="events__wrapper">
					<div class="events__column">
						<h3 class="events__subhead">Предстоящие мероприятия</h3>
						<ul class="events__list">
							<li class="events__item">
								<div class="events__item-time">
									<div class="events__item-date">25</div>
									<div class="events__item-month">Ноября</div>
								</div>
								<div class="events__item-info">
									<div class="events__item-title">Семейный праздник «Капустки»</div>
									<div class="events__item-description">
										Место: Банкетный зал, «Семейный парк отдыха «Карасики», ул. Отдыха 111/1 С
										собой: капуста, морковь, соль, ножи, доски, тёрки, сечка, корыто, вода, чайник,
										заварка, кружка, домашние выпечка или сладости.
									</div>
								</div>
								<button class="events__item-button">Участвую</button>
							</li>
						</ul>
					</div>
					<div class="events__column">
						<h3 class="events__subhead">Прошедшие мероприятия</h3>
						<ul class="events__list">
							<li class="events__item">
								<div class="events__item-time">
									<div class="events__item-date">04</div>
									<div class="events__item-month">Ноября</div>
								</div>
								<div class="events__item-info">
									<div class="events__item-title">Праздничное Дмитриевское гуляние</div>
									<div class="events__item-description">
										4 ноября 2023 года в парке "Таганская слобода" соберутся настоящие смельчаки,
										желающие испытать себя в этой древней потехе. Участники смогут принять участие в
										захватывающих боях, где сила, ловкость и мастерство будут настоящими ключами к
										победе.
									</div>
								</div>
							</li>
							<li class="events__item">
								<div class="events__item-time">
									<div class="events__item-date">25</div>
									<div class="events__item-month">Июля</div>
								</div>
								<div class="events__item-info">
									<div class="events__item-title">День русской культуры</div>
									<div class="events__item-description">
										Это семейное мероприятие - поэтому берите с собой жен, мужей, детей. В
										программе: общение, совместное приготовление риса с мясом и окрошки, развлечения
										для детей, мастер-классы по ремёслам, реконструкция средневековых сражений,
										стеночный кулачный бой (берите капы и перчатки 12 унций).
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Events"
};
</script>

<style lang="scss" scoped>
.events {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__wrapper {
		display: flex;
	}

	&__column {
		width: 50%;
		padding: 0;

		&:first-of-type {
			border-right: 2px solid #aeaeae;
			padding-right: 10px;
		}
		&:last-of-type {
			padding-left: 10px;
		}
	}

	&__subhead {
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border: 2px solid #aeaeae;
		border-radius: 10px;
		padding: 10px;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&-time {
			display: flex;
			flex-direction: column;
			align-items: center;
			min-width: 80px;
			height: 80px;
			padding: 10px;
			color: #fff;
			border-radius: 10px;
			background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		}

		&-date {
			font-size: 26px;
		}

		&-month {
			font-size: 14px;
			text-transform: uppercase;
		}

		&-info {
			display: flex;
			flex-direction: column;
			margin: 0 10px;
		}

		&-title {
			font-weight: 500;
			color: #8c8c8c;
			text-transform: uppercase;
			margin-bottom: 5px;
		}

		&-button {
			background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
			border: 0;
			border-radius: 10px;
			color: #fff;
			font-size: 16px;
			padding: 10px;
			cursor: pointer;
		}
	}
}
</style>
