export default {
	state: {
		header: {
			id: null,
			page: "/",
			pages: [
				{
					name: "home",
					title: "Главная",
					path: "/home"
				},
				/* {
          name: "events",
          title: "Мероприятия",
          path: "/events",
        },
        {
          name: "people",
          title: "Наши люди",
          path: "/people",
        },
        {
          name: "culture",
          title: "Наша культура",
          path: "/culture",
        }, */
				{
					name: "about",
					title: "О нас",
					path: "/about"
				},
				{
					name: "team",
					title: "Совет общины",
					path: "/team"
				},
				{
					name: "contacts",
					title: "Контакты",
					path: "/contacts"
				},
				{
					name: "easter",
					title: "Пасха",
					path: "/easter"
				}
			]
		}
	},
	getters: {
		getHeader: state => state.header
	},
	mutations: {},
	actions: {}
};
