<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__wrapper">
        <div class="footer__copyrights">© 2023 All rights reserved</div>
        <div class="footer__socials">
          <ul class="footer__socials-list">
            <li class="footer__socials-item">
              <a class="footer__socials-link" href="">
                <font-awesome-icon icon="fa-solid fa-star" />
              </a>
            </li>
            <li class="footer__socials-item">
              <a class="footer__socials-link" href="">
                <font-awesome-icon icon="fa-solid fa-star" />
              </a>
            </li>
            <li class="footer__socials-item">
              <a class="footer__socials-link" href="">
                <font-awesome-icon icon="fa-solid fa-star" />
              </a>
            </li>
            <li class="footer__socials-item">
              <a class="footer__socials-link" href="">
                <font-awesome-icon icon="fa-solid fa-star" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "footer",
  };
</script>

<style lang="scss">
  .footer {
    width: 100%;
    background-color: $colorLight;
    border-top: $border;
    padding: 30px 0;

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__copyrights {
      text-transform: uppercase;
      font-weight: 500;
    }

    &__socials {
      &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
      }

      &-item {
        margin: 0 5px;
      }

      &-link {
        text-decoration: none;
      }
    }
  }
</style>
