<template>
	<div class="team">
		<main class="team__main">
			<div class="team__content">
				<h2 class="team__headline">Совет общины</h2>
				<div class="team__intro">
					<div class="team__preface">
						В данном описана структура общины
					</div>
				</div>
				<ul class="team__list">
					<li class="team__item">
						<img class="team__image" :src="require('../assets/images/unknown.jpg')" alt="" />
						<div class="team__info">
							<div class="team__name">Сергей Владимирович</div>
							<div class="team__position">Координатор</div>
							<div class="team__description">
								Отвечает за функционирование Русской Общины
							</div>
						</div>
					</li>
					<li class="team__item">
						<img class="team__image" :src="require('../assets/images/unknown.jpg')" alt="" />
						<div class="team__info">
							<div class="team__name">Станислав Алексеевич</div>
							<div class="team__position">Заместитель по юридическим вопросам</div>
							<div class="team__description">
								Отвечает за юридическую составляющую Русской Общины
							</div>
						</div>
					</li>
					<li class="team__item">
						<img class="team__image" :src="require('../assets/images/unknown.jpg')" alt="" />
						<div class="team__info">
							<div class="team__name">Александр Петрович</div>
							<div class="team__position">Заместитель по идеологическим вопросам</div>
							<div class="team__description">
								Отвечает за идеологическую составляющую Русской Общины
							</div>
						</div>
					</li>
					<li class="team__item">
						<img class="team__image" :src="require('../assets/images/unknown.jpg')" alt="" />
						<div class="team__info">
							<div class="team__name">Александр Петрович</div>
							<div class="team__position">Заместитель по духовным вопросам</div>
							<div class="team__description">
								Отвечает за духовное развитие Русской Общины
							</div>
						</div>
					</li>
					<li class="team__item">
						<img class="team__image" :src="require('../assets/images/unknown.jpg')" alt="" />
						<div class="team__info">
							<div class="team__name">Сергей Алексеевич</div>
							<div class="team__position">Заместитель по спортивным вопросам</div>
							<div class="team__description">
								Отвечает за спортивную составляющую Русской Общины
							</div>
						</div>
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Team"
};
</script>

<style lang="scss" scoped>
.team {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;

		@include mediaquery(md) {
			width: 70px;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		margin: 20px 0 0 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		align-items: flex-start;
		margin-bottom: 15px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__image {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		border: 2px solid #b0b0b0;

		@include mediaquery(md) {
			width: 100px;
			height: 100px;
		}
	}

	&__info {
		margin-left: 20px;
	}

	&__name {
		font-size: 20px;
		font-weight: 600;
	}

	&__position {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	&__description {
		font-size: 14px;
	}
}
</style>
