<template>
	<div class="service">
		<main class="service__main">
			<div class="service__content">
				<h2 class="service__headline">Услуги: медицина</h2>
				<div class="service__wrapper">
					<div class="service__info">
						<div class="service__description">
							<h3>Образование</h3>
							<p>
								26.06.2015г. окончила ГБОУ ВПО "Читинская государственная медицинская академия"
								Министерства здравоохранения РФ по специальности: "Лечебное дело".
							</p>
							<p>
								27.06.2016г. прошла интернатуру при ГБОУ ВПО "Читинская государственная медицинская
								академия" Министерства здравоохранения РФ п специальности "Терапия".
							</p>
							<p>
								05.07.2018г. прошла ординатуру при Федеральном государственном бюджетном научном
								учреждении "Томский национальный исследовательский медицинский цент Российской академии
								наук" г. Томск, по специальности: "Функциональная диагностика".
							</p>
							<p>
								05.07.2018г. освоила основную профессиональную образовательную программу ординатуры по
								специальности "Функциональная диагностика".
							</p>
							<p>
								30.12.2019г. прошла профессиональную переподготовку при ООО "Университет постдипломного
								профессионального образования" по программе " Организация здравоохранения и общественное
								здоровье".
							</p>
						</div>
					</div>
					<div class="service__about">
						<img
							class="service__about-photo"
							:src="require('../assets/images/profile__photo.png')"
							alt=""
						/>
						<div class="service__about-name">Иванова Ольга Сергеевна</div>
						<div class="service__about-position">Должность: Врач-терапевт</div>
						<div class="service__about-experience">Опыт работы: 11 лет</div>
						<button class="service__request">Связаться</button>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Service"
};
</script>

<style lang="scss" scoped>
.service {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
		}
	}

	&__info {
		width: 100%;

		@include mediaquery(md) {
			width: 70%;
			padding-right: 30px;
		}

		h3 {
			color: #8c8c8c;
			font-size: 20px;
		}

		p {
			color: #8c8c8c;
			font-size: 16px;
		}
	}

	&__about {
		width: 100%;
		text-align: center;
		order: -1;

		@include mediaquery(md) {
			order: 1;
			width: 30%;
		}

		&-photo {
			width: 100%;
			border-radius: 10px;
			border: 2px solid #1768c7;
		}

		&-name {
			color: #2177dc;
			font-size: 18px;
			font-weight: 500;
			margin-top: 13px;
		}

		&-position,
		&-experience {
			color: #8c8c8c;
			font-size: 16px;
			margin-top: 7px;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;
		margin-top: 20px;
	}
}
</style>
