import { createRouter, createWebHistory } from "vue-router";

import About from "../layout/pages/About.vue";
import Contacts from "../layout/pages/Contacts.vue";
import Culture from "../layout/pages/Culture.vue";
import Easter from "../layout/pages/Easter.vue";
import Events from "../layout/pages/Events.vue";
import Help from "../layout/pages/Help.vue";
import Hitch from "../layout/pages/Hitch.vue";
import Home from "../layout/pages/Home.vue";
import Job from "../layout/pages/Job.vue";
import Money from "../layout/pages/Money.vue";
import Nothing from "../layout/pages/Nothing.vue";
import People from "../layout/pages/People.vue";
import Profile from "../layout/pages/Profile.vue";
import Projects from "../layout/pages/Projects.vue";
import Services from "../layout/pages/Services.vue";
import Service from "../layout/pages/Service.vue";
import Store from "../layout/pages/Store.vue";
import Taxi from "../layout/pages/Taxi.vue";
import Team from "../layout/pages/Team.vue";
import Sport from "../layout/pages/Sport.vue";

const routes = [
	{
		path: "/",
		redirect: "/home"
	},
	{
		path: "/about",
		name: "about",
		component: About
	},
	{
		path: "/contacts",
		name: "contacts",
		component: Contacts
	},
	{
		path: "/culture",
		name: "culture",
		component: Culture
	},
	{
		path: "/easter",
		name: "easter",
		component: Easter
	},
	{
		path: "/events",
		name: "events",
		component: Events
	},
	{
		path: "/help",
		name: "help",
		component: Help
	},
	{
		path: "/hitch",
		name: "hitch",
		component: Hitch
	},
	{
		path: "/home",
		name: "home",
		component: Home
	},
	{
		path: "/job",
		name: "job",
		component: Job
	},
	{
		path: "/money",
		name: "money",
		component: Money
	},
	{
		path: "/people",
		name: "people",
		component: People
	},
	{
		path: "/profile",
		name: "profile",
		component: Profile
	},
	{
		path: "/projects",
		name: "projects",
		component: Projects
	},
	{
		path: "/services",
		name: "services",
		component: Services
	},
	{
		path: "/service",
		name: "service",
		component: Service
	},
	{
		path: "/store",
		name: "store",
		component: Store
	},
	{
		path: "/taxi",
		name: "taxi",
		component: Taxi
	},
	{
		path: "/team",
		name: "team",
		component: Team
	},
	{
		path: "/sport",
		name: "sport",
		component: Sport
	},
	{
		path: "/:pathMatch(.*)",
		name: "nothing",
		component: Nothing
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	const publicPages = [
		"/about",
		"/contacts",
		"/culture",
		"/easter",
		"/events",
		"/help",
		"/hitch",
		"/home",
		"/job",
		"/money",
		"/nothing",
		"/people",
		"/profile",
		"/projects",
		"/services",
		"/service",
		"/store",
		"/taxi",
		"/team",
		"/sport"
	];

	if (!publicPages.includes(to.path)) next("/nothing");
	else next();
});

export default router;
