<template>
	<div class="job">
		<main class="job__main">
			<div class="job__content">
				<h2 class="job__headline">Работа</h2>
				<div class="job__intro">
					<div class="job__preface">
						Данный раздел создан для поиска вакансий и предложения работы. Вы можете добавить вакансию или
						резюме, нажав на кнопку "Добавить".
					</div>
					<button class="job__request">Добавить</button>
				</div>
				<div>
					<h3 class="job__caption">Специализации</h3>
				</div>
				<ul class="job__categories">
					<li class="job__category">
						<div class="job__category-icon">
							<font-awesome-icon :icon="['fas', 'briefcase-medical']" />
						</div>
						<div class="job__category-name">Медицина</div>
						<div class="job__category-total">Всего предложений: 1</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon"><font-awesome-icon :icon="['fas', 'utensils']" /></div>
						<div class="job__category-name">Общественное питание</div>
						<div class="job__category-total">Всего предложений: 4</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon"><font-awesome-icon :icon="['fas', 'car']" /></div>
						<div class="job__category-name">Ремонт автомобилей</div>
						<div class="job__category-total">Всего предложений: 2</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon"><font-awesome-icon :icon="['fas', 'user-shield']" /></div>
						<div class="job__category-name">Безопасность</div>
						<div class="job__category-total">Всего предложений: 1</div>
					</li>

					<li class="job__category">
						<div class="job__category-icon"><font-awesome-icon :icon="['fas', 'truck']" /></div>
						<div class="job__category-name">Грузоперевозки</div>
						<div class="job__category-total">Всего предложений: 1</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon">
							<font-awesome-icon :icon="['fas', 'screwdriver-wrench']" />
						</div>
						<div class="job__category-name">Ремонт техники</div>
						<div class="job__category-total">Всего предложений: 3</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon">
							<font-awesome-icon :icon="['fas', 'graduation-cap']" />
						</div>
						<div class="job__category-name">Образование</div>
						<div class="job__category-total">Всего предложений: 2</div>
					</li>
					<li class="job__category">
						<div class="job__category-icon"><font-awesome-icon :icon="['fas', 'faucet-drip']" /></div>
						<div class="job__category-name">ЖКХ</div>
						<div class="job__category-total">Всего предложений: 2</div>
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Job"
};
</script>

<style lang="scss" scoped>
.job {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}

	&__caption {
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__categories {
		margin: 0 -10px 20px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	&__category {
		border-radius: 5px;
		border: 2px solid #aeaeae;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 10px;
		cursor: pointer;

		@include mediaquery(md) {
			width: calc(25% - 20px);
		}

		&-icon {
			font-size: 40px;
			color: #8c8c8c;
		}

		&-name {
			color: #8c8c8c;
			font-size: 20px;
			margin-top: 10px;
		}

		&-total {
			margin-top: 10px;
			font-size: 16px;
			color: #1768c7;
		}
	}
}
</style>
