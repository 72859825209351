<template>
	<div class="store">
		<main class="store__main">
			<div class="store__content">
				<h2 class="store__headline">Магазин</h2>
				<div class="store__intro">
					<div class="store__preface">
						В данном разделе можно что-то купить или продать, используя ресурс Русской Общины. Вы можете
						предложить свои товары, нажав на кнопку "Предложить".
					</div>
					<button class="store__request">Предложить</button>
				</div>
				<div>
					<h3 class="store__caption">Категории товаров</h3>
				</div>
				<ul class="store__categories">
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'building']" /></div>
						<div class="store__category-name">Недвижимость</div>
						<div class="store__category-total">Всего предложений: 1</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'car']" /></div>
						<div class="store__category-name">Автомобили</div>
						<div class="store__category-total">Всего предложений: 2</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'computer']" /></div>
						<div class="store__category-name">Электроника</div>
						<div class="store__category-total">Всего предложений: 11</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'couch']" /></div>
						<div class="store__category-name">Мебель</div>
						<div class="store__category-total">Всего предложений: 0</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'shirt']" /></div>
						<div class="store__category-name">Одежда</div>
						<div class="store__category-total">Всего предложений: 5</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon">
							<font-awesome-icon :icon="['fas', 'screwdriver-wrench']" />
						</div>
						<div class="store__category-name">Инструмент</div>
						<div class="store__category-total">Всего предложений: 7</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'volleyball']" /></div>
						<div class="store__category-name">Спортивный инвентарь</div>
						<div class="store__category-total">Всего предложений: 15</div>
					</li>
					<li class="store__category">
						<div class="store__category-icon"><font-awesome-icon :icon="['fas', 'dog']" /></div>
						<div class="store__category-name">Питомцы</div>
						<div class="store__category-total">Всего предложений: 1</div>
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Store"
};
</script>

<style lang="scss" scoped>
.store {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}

	&__caption {
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__categories {
		margin: 0 -10px 20px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	&__category {
		border-radius: 5px;
		border: 2px solid #aeaeae;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 10px;
		cursor: pointer;

		@include mediaquery(md) {
			width: calc(25% - 20px);
		}

		&-icon {
			font-size: 40px;
			color: #8c8c8c;
		}

		&-name {
			color: #8c8c8c;
			font-size: 20px;
			margin-top: 10px;
		}

		&-total {
			margin-top: 10px;
			font-size: 16px;
			color: #1768c7;
		}
	}
}
</style>
