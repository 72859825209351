<template>
	<Modal />
	<Preloader />
	<Header v-if="true" />

	<div class="page">
		<Menu />
		<div class="container">
			<router-view />
		</div>
	</div>
	<Footer v-if="false" />
</template>

<script>
import Header from "./layout/modules/Header.vue";
import Menu from "./layout/modules/Menu.vue";
import Modal from "./layout/modules/Modal.vue";
import Preloader from "./layout/modules/Preloader.vue";
import Footer from "./layout/modules/Footer.vue";

export default {
	name: "App",
	components: {
		Header,
		Menu,
		Modal,
		Preloader,
		Footer
	}
};
</script>

<style lang="scss">
*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%;
	height: 100%;
	font-family: "Roboto";
	font-size: 13px;
}

body {
	height: 100%;
	min-width: 375px;
	margin: 0;
	padding: 10px;
	background: #fff;
	color: $colorDark;
}

button,
input,
select,
textarea,
a {
	outline: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	box-shadow: none;
	font-family: "Roboto";
	color: $colorDark;
}

.page {
	display: flex;
	align-items: flex-start;
	min-height: 70vh;
	margin-top: 15px;
	padding-bottom: 50px;
}

.container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	//margin: 0 auto;
	//padding: 0 20px;

	@include mediaquery(lg) {
		margin-left: 15px;
	}

	/* @include mediaquery(sm) {
      max-width: 550px;
      padding: 0;
    }

    @include mediaquery(md) {
      max-width: 720px;
    }

    @include mediaquery(lg) {
      max-width: 960px;
    }

    @include mediaquery(xl) {
      max-width: 1280px;
    }

    @include mediaquery(hg) {
      max-width: 1450px;
    }

    @include mediaquery(xh) {
      max-width: 1600px;
    } */
}
</style>
