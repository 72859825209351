import App from "./App.vue";
import router from './router';
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import '@vuepic/vue-datepicker/dist/main.css';

import {
  library
} from "@fortawesome/fontawesome-svg-core";
import {
  fas
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

library.add(fas);

import io from 'socket.io-client';

import {
  createApp
} from "vue";


import {
  store
} from './store';


const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueApexCharts)
  .use(PerfectScrollbar)

const clickOutside = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = (evt) => {
      if (!(el == evt.target || el.contains(evt.target))) {
        binding.value(evt, el);
      }
    };
    window.requestAnimationFrame(() => {
      document.addEventListener("click", el.clickOutsideEvent);
    });
  },
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app.config.globalProperties.$soketio = io('https://api.airwet.app');

app.directive("click-outside", clickOutside).component("font-awesome-icon", FontAwesomeIcon).mount("#app");