<template>
	<header class="header">
		<div class="header__wrapper">
			<div class="header__logo">
				<img class="header__logo-image" :src="require('../assets/images/logo.svg')" alt="" />
			</div>
			<div class="header__menu">
				<div class="header__navbar" @click="handleNavbarClick">
					<ul class="header__navbar-list">
						<router-link
							v-for="(page, index) in header.pages"
							class="header__navbar-link"
							active-class="header__navbar-link_active"
							exact-active-class=""
							:key="index"
							:to="page.path"
						>
							{{ page.title }}
						</router-link>
					</ul>
				</div>
				<div class="header__toggle" @click="toggleNavbar">
					<span class="header__toggle-line"></span>
					<span class="header__toggle-line"></span>
					<span class="header__toggle-line"></span>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "Header",
	data() {
		return {
			headerDate: null
		};
	},
	computed: {
		...mapGetters({
			header: "getHeader"
		})
	},
	methods: {
		toggleNavbar() {
			document.querySelector(".header__navbar").classList.toggle("header__navbar_visible");
		},
		handleNavbarClick(event) {
			if (event.target.closest(".header__navbar-link")) {
				document.querySelector(".header__navbar").className = "header__navbar";
			}
		}
	}
};
</script>

<style lang="scss">
.header {
	width: 100%;
	position: relative;
	border-radius: 10px;
	border: 2px solid #b0b0b0;
	padding: 10px;

	&__wrapper {
		width: 100%;
		display: flex;
		position: relative;
	}

	&__logo {
		width: 120px;
		display: flex;
		align-items: center;
		margin-right: 20px;

		&-image {
			width: 100%;
		}
	}

	&__navbar {
		display: none;
		position: absolute;
		left: 0;
		top: 53px;
		width: 100%;
		z-index: $zIndexHeader;

		&_visible {
			display: block;
		}

		@include mediaquery(md) {
			display: flex;
			align-items: center;
			position: static;
			left: auto;
			top: auto;
			width: auto;
			height: 40px;
		}

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-direction: column;
			background: #e6e6e6;

			@include mediaquery(md) {
				flex-direction: row;
				background: none;
			}
		}

		&-link {
			margin: 5px 0;
			font-size: 20px;
			text-decoration: none;
			text-transform: uppercase;
			color: #8c8c8c;
			padding: 0 10px;

			@include mediaquery(md) {
				margin: 0 5px;
				font-size: 16px;
				padding: 0;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:hover,
			&_active {
				color: #1768c7;
			}
		}
	}

	&__toggle {
		position: absolute;
		top: -2px;
		right: 0;
		width: 45px;
		height: 40px;
		padding: 0 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: $border;
		border-width: 2px;
		border-radius: 4px;
		cursor: pointer;

		@include mediaquery(md) {
			display: none;
		}

		&-line {
			height: 2px;
			background-color: lighten($colorDark, 60%);
			width: 100%;
			margin: 4px 0;
		}
	}
}
</style>
