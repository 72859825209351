<template>
	<div class="taxi">
		<main class="taxi__main">
			<div class="taxi__content">
				<h2 class="taxi__headline">Такси</h2>
				<div class="taxi__intro">
					<div class="taxi__preface">
						В данном разделе можно заказать, либо предложить услуги частного извоза, используя ресурс
						Русской Общины. Вы можете предложить свой вариант, нажав на кнопку "Предложить".
					</div>
					<button class="taxi__request">Предложить</button>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Taxi"
};
</script>

<style lang="scss" scoped>
.taxi {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}
}
</style>
