export default {
  state: {
    routine: {},
  },
  getters: {
    getRoutine: state => state.routine,
  },
  mutations: {
    updateRoutine(state) {
      state.routine = {
        list: [{
            id: 0,
            days: [{
                id: 0,
                day: 'Monday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 1,
                day: 'Tuesday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 3,
                day: 'Wednesday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 4,
                day: 'Thursday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 5,
                day: 'Friday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 6,
                day: 'Saturday',
                time: '07:00',
                action: 'Подъем',
              },
              {
                id: 7,
                day: 'Sunday',
                time: '07:00',
                action: 'Подъем',
              },
            ],
          },
          {
            id: 1,
            days: [{
                id: 0,
                day: 'Monday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 1,
                day: 'Tuesday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 3,
                day: 'Wednesday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 4,
                day: 'Thursday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 5,
                day: 'Friday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 6,
                day: 'Saturday',
                time: '08:00',
                action: 'Зарядка',
              },
              {
                id: 7,
                day: 'Sunday',
                time: '08:00',
                action: 'Зарядка',
              },
            ],
          },
          {
            id: 2,
            days: [{
                id: 0,
                day: 'Monday',
                time: '10:00',
                action: 'Стакан воды',
              },
              {
                id: 1,
                day: 'Tuesday',
                time: '10:00',
                action: 'Стакан воды',
              },
              {
                id: 3,
                day: 'Wednesday',
                time: '10:00',
                action: 'Стакан воды',
              },
              {
                id: 4,
                day: 'Thursday',
                time: '10:00',
                action: 'Стакан воды',
              },
              {
                id: 5,
                day: 'Friday',
                time: '10:00',
                action: 'Стакан воды',
              },
              {
                id: 6,
                day: 'Saturday',
                time: null,
                action: null,
              },
              {
                id: 7,
                day: 'Sunday',
                time: null,
                action: null,
              },
            ],
          },
          {
            id: 2,
            days: [{
                id: 0,
                day: 'Monday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 1,
                day: 'Tuesday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 3,
                day: 'Wednesday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 4,
                day: 'Thursday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 5,
                day: 'Friday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 6,
                day: 'Saturday',
                time: '11:00',
                action: 'Прием пищи',
              },
              {
                id: 7,
                day: 'Sunday',
                time: '11:00',
                action: 'Прием пищи',
              },
            ],
          }
        ]
      }
    }
  },
  actions: {
    fetchRoutine(context) {
      context.commit("updateRoutine");
    },
  }
}