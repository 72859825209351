<template>
  <div class="about">
    <main class="about__main">
      <div class="about__content">
        <h2 class="about__headline">Финансы</h2>

        
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    name: "About",
  };
</script>

<style lang="scss" scoped>
  .about {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
      width: 100%;
    }

    &__headline {
      @include headline;
    }
  }
</style>