import router from "@/router";

export default {
	state: {
		content: {
			page: "/",
			id: null,
			pages: [
				{
					name: "Profile",
					title: "Профиль",
					path: "/profile",
					icon: "address-card"
				},
				/* {
					name: "Help",
					title: "Помощь",
					path: "/help",
					icon: "fa-handshake-angle"
				}, */
				{
					name: "Job",
					title: "Работа",
					path: "/job",
					icon: "fa-person-digging"
				},
				{
					name: "Services",
					title: "Услуги",
					path: "/services",
					icon: "fa-bell-concierge"
				},
				{
					name: "Store",
					title: "Магазин",
					path: "/store",
					icon: "fa-store"
				},
				{
					name: "Sport",
					title: "Спорт",
					path: "/sport",
					icon: "fa-dumbbell"
				},
				{
					name: "Hitch",
					title: "Попутка",
					path: "/hitch",
					icon: "fa-car-side"
				},
				{
					name: "Taxi",
					title: "Такси",
					path: "/taxi",
					icon: "fa-taxi"
				},
				{
					name: "Projects",
					title: "Проекты",
					path: "/projects",
					icon: "fa-diagram-project"
				},
				/* {
          name: "Money",
          title: "Финансы",
          path: "/money",
          icon: "fa-piggy-bank",
        }, */
				{
					name: "Login",
					title: "Выход",
					path: "/login",
					icon: "fa-right-from-bracket"
				}
			]
		},
		patterns: {
			email: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
			email_add: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
			phone: /^[+]\d{1}\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$|^7[0-9]{10,10}$/,
			password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			password_old: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			password_new: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			password_confirm: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			confirm: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
			date: /(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}/,
			code: /^[0-9]{4,4}$/,
			name: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
			first_name: /^[а-яА-ЯёЁ]{2,30}$/,
			middle_name: /^[а-яА-ЯёЁ]{2,30}$/,
			last_name: /^[а-яА-ЯёЁ]{2,30}$/,
			comment: /^[а-яА-ЯёЁa-zA-Z0-9-\s]{2,30}$/,
			number: /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
			holder: /^[a-zA-Z]{2,30} [a-zA-Z]{2,30}$/,
			cvc: /^[0-9]{3,3}$/,
			contract: /^[а-яА-ЯёЁa-zA-Z0-9-\s]{2,30}$/,
			address: /^[а-яА-ЯёЁ0-9-\s,.]{2,50}$/,
			addr: /^[0-9]{1,5}$/,
			quantity: /^[0-9]{1,2}$/,
			humidity: /^[0-9]{1,3}$/,
			temp: /^[0-9]{1,2}$/,
			p_in_min: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_in_max: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_out_min: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_out_nom: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_out_max: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_precharge: /^(0|[1-9]\d*)([.]\d+)?$/,
			discharge_timeout: /^(0|[1-9]\d*)([.]\d+)?$/,
			precharge_timeout: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_delay: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_timeout: /^(0|[1-9]\d*)([.]\d+)?$/,
			pump_rpm: /^(0|[1-9]\d*)([.]\d+)?$/,
			Kp: /^(0|[1-9]\d*)([.]\d+)?$/,
			Ki: /^(0|[1-9]\d*)([.]\d+)?$/,
			Kd: /^(0|[1-9]\d*)([.]\d+)?$/,
			p_drain: /^(0|[1-9]\d*)([.]\d+)?$/,
			t_drain: /^(0|[1-9]\d*)([.]\d+)?$/,
			t_open: /^(0|[1-9]\d*)([.]\d+)?$/,
			t_close: /^(0|[1-9]\d*)([.]\d+)?$/,
			hum_min: /^[0-9]{1,3}$/,
			hum_max: /^[0-9]{1,3}$/
		},
		modal: {
			current: null,
			password: {
				type: "password",
				header: "Изменение пароля",
				body: [
					{
						id: 0,
						kind: "single",
						name: "password_old",
						title: "Введите старый пароль",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры"
					},
					{
						id: 1,
						kind: "single",
						name: "password_new",
						title: "Введите новый пароль",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры"
					},
					{
						id: 2,
						kind: "single",
						name: "password_confirm",
						title: "Подтвердите новый пароль",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры"
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Изменить пароль",
						paint: "blue",
						action: "changePassword",
						disabled: true
					}
				]
			},
			document: {
				type: "document",
				header: "Добавление документа",
				body: [
					{
						id: 0,
						kind: "file",
						input: {
							name: "name",
							title: "Название документа",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						button: {
							value: "add",
							disabled: false
						},
						status: "default"
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Добавить документ",
						paint: "blue",
						action: "addDocument",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gary",
						action: "cancel",
						disabled: false
					}
				]
			},
			object: {
				type: "object",
				header: "Создание объекта",
				body: [
					{
						id: 0,
						kind: "single",
						name: "type",
						title: "Тип объекта",
						value: "",
						dropdown: {
							type: "simple",
							name: "",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 0,
									name: "home",
									title: "Индивидуальная"
								},
								{
									id: 1,
									name: "pro",
									title: "Централизованная"
								},
								{
									id: 2,
									name: "lite",
									title: "Локальная"
								}
							]
						}
					},
					{
						id: 1,
						kind: "single",
						name: "address",
						title: "Адрес объекта",
						value: "",
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 2,
						kind: "double",
						first: {
							type: "input",
							name: "name",
							title: "Название объекта",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						last: {
							type: "phone",
							name: "phone",
							title: "Телефон владельца",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						status: "default"
					},
					{
						id: 3,
						kind: "double",
						first: {
							type: "datepicker",
							name: "date",
							title: "Дата договора",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: false
						},
						last: {
							type: "input",
							name: "contract",
							title: "Номер договора",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: false
						},
						status: "default"
					},
					{
						id: 4,
						kind: "set",
						name: "owner",
						title: "Владелец",
						value: "",
						dropdown: {
							type: "simple",
							name: "",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 0,
									name: "exist",
									title: "Существующий пользователь"
								},
								{
									id: 1,
									name: "new",
									title: "Новый пользователь"
								}
							]
						},
						set: {
							new: [
								{
									id: 5,
									kind: "single",
									name: "last_name",
									title: "Фамилия владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 6,
									kind: "single",
									name: "first_name",
									title: "Имя владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 7,
									kind: "single",
									name: "middle_name",
									title: "Отчество владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 8,
									kind: "single",
									name: "email",
									title: "Электронная почта владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 9,
									kind: "single",
									name: "password",
									title: "Пароль владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры"
								}
							],
							exist: [
								{
									id: 5,
									kind: "single",
									name: "email",
									title: "Электронная почта владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								}
							]
						}
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Создать объект",
						paint: "blue",
						action: "createObject",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gray",
						action: "cancel",
						disabled: false
					}
				]
			},
			zone: {
				type: "zone",
				header: "Добавление зоны",
				body: [
					{
						id: 0,
						kind: "single",
						name: "name",
						title: "Название зоны",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 1,
						kind: "single",
						name: "num",
						title: "Номер зоны",
						value: "",
						dropdown: {
							type: "simple",
							name: "",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: []
						}
					},
					{
						id: 2,
						kind: "setup",
						status: "valid",
						first: {
							name: "humidity",
							title: "Влажность зоны",
							value: 45,
							default: 45,
							unit: "%",
							status: "valid",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						},
						last: {
							name: "temp",
							title: "Температура зоны",
							value: 22,
							default: 22,
							unit: "°",
							status: "valid",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						}
					},
					{
						id: 3,
						kind: "module",
						status: "valid",
						dropdown: {
							type: "simple",
							name: "device",
							title: "Тип устройства",
							value: null,
							alias: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 1,
									name: "s",
									title: "Датчик"
								},
								{
									id: 2,
									name: "g",
									title: "Гигростат"
								}
							]
						},
						input: {
							name: "addr",
							title: "Адрес",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						button: {
							value: "add",
							disabled: true
						},
						modules: []
					},
					{
						id: 4,
						kind: "module",
						status: "valid",
						dropdown: {
							type: "simple",
							name: "nozzle",
							title: "Тип форсунок",
							value: null,
							alias: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 1,
									name: "01L",
									title: "01L"
								},
								{
									id: 2,
									name: "02L",
									title: "02L"
								}
							]
						},
						input: {
							name: "quan",
							title: "Количество",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						button: {
							value: "add",
							disabled: true
						},
						modules: []
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Добавить зону",
						paint: "blue",
						action: "addZone",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gray",
						action: "cancel",
						disabled: false
					}
				]
			},
			contact: {
				type: "contact",
				header: "Добавление контакта",
				body: [
					{
						id: 0,
						kind: "single",
						name: "last_name",
						title: "Фамилия",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 1,
						kind: "single",
						name: "first_name",
						title: "Имя",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 3,
						kind: "single",
						name: "middle_name",
						title: "Отчество",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 4,
						kind: "single",
						name: "comment",
						title: "Комментарий",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 5,
						kind: "single",
						name: "phone",
						title: "Телефон",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 6,
						kind: "single",
						name: "email",
						title: "Электронная почта",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Добавить контакт",
						paint: "blue",
						action: "addContact",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gary",
						action: "cancel",
						disabled: false
					}
				]
			},
			owner: {
				type: "owner",
				header: "Изменение владельца системы",
				body: [
					{
						id: 1,
						kind: "set",
						name: "owner",
						title: "Владелец",
						value: "",
						dropdown: {
							type: "simple",
							name: "",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 0,
									name: "exist",
									title: "Существующий пользователь"
								},
								{
									id: 1,
									name: "new",
									title: "Новый пользователь"
								}
							]
						},
						set: {
							new: [
								{
									id: 5,
									kind: "single",
									name: "last_name",
									title: "Фамилия владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 6,
									kind: "single",
									name: "first_name",
									title: "Имя владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 7,
									kind: "single",
									name: "middle_name",
									title: "Отчество владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 8,
									kind: "single",
									name: "phone",
									title: "Телефон владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 9,
									kind: "single",
									name: "email",
									title: "Электронная почта владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								},
								{
									id: 10,
									kind: "single",
									name: "password",
									title: "Пароль владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры"
								}
							],
							exist: [
								{
									id: 5,
									kind: "single",
									name: "email",
									title: "Электронная почта владельца",
									value: null,
									status: "default",
									error: false,
									errorMessage: "Неверный формат"
								}
							]
						}
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Изменить владельца",
						paint: "blue",
						action: "changeOwner",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gary",
						action: "cancel",
						disabled: false
					}
				]
			},
			card: {
				type: "card",
				header: "Добавление карты",
				body: [
					{
						id: 0,
						kind: "single",
						name: "system",
						title: "Платежная система",
						value: "",
						status: "default",
						dropdown: {
							type: "simple",
							name: "",
							value: "",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 0,
									name: "mir",
									title: "Мир"
								},
								{
									id: 1,
									name: "visa",
									title: "Visa"
								},
								{
									id: 2,
									name: "mastercard",
									title: "Mastercard"
								}
							]
						}
					},
					{
						id: 1,
						kind: "single",
						name: "number",
						title: "Номер карты",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 2,
						kind: "single",
						name: "holder",
						title: "Владелец карты",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					},
					{
						id: 3,
						kind: "triple",
						status: "default",
						first: {
							type: "picker",
							name: "month",
							title: "Месяц",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						middle: {
							type: "picker",
							name: "year",
							title: "Год",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						},
						last: {
							type: "cvc",
							name: "cvc",
							title: "CVC",
							value: null,
							status: "default",
							error: false,
							errorMessage: "Неверный формат"
						}
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Добавить карту",
						paint: "blue",
						action: "addCard",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gary",
						action: "cancel",
						disabled: false
					}
				]
			},
			payment: {
				type: "payment",
				header: "Оплата услуги",
				body: [
					{
						id: 0,
						kind: "single",
						name: "contract",
						title: "Номер договора",
						value: "№А-5644001 от 13.12.2021",
						status: "default",
						error: false,
						errorMessage: "Неверный формат",
						disabled: true
					},
					{
						id: 1,
						kind: "single",
						name: "address",
						title: "Адрес системы",
						value: "г. Бобалево, ул. Маневровая 156, кв 8",
						status: "default",
						error: false,
						errorMessage: "Неверный формат",
						disabled: true
					},
					{
						id: 2,
						kind: "double",
						first: {
							name: "date",
							title: "Срок уплаты",
							value: "01.05.2022",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						},
						last: {
							name: "cost",
							title: "Стоимость",
							value: "1 389 руб. 09 коп.",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						}
					},
					{
						id: 3,
						kind: "single",
						title: "Карта оплаты",
						dropdown: {
							type: "card",
							name: "card",
							system: "mir",
							value: "**** **** **** 5610",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							categories: [
								{
									id: 1,
									name: "1",
									system: "mir",
									title: "**** **** **** 5610"
								},
								{
									id: 2,
									name: "2",
									system: "visa",
									title: "**** **** **** 2880"
								}
							]
						}
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Оплатить",
						paint: "blue",
						action: "payService",
						disabled: true
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gray",
						action: "cancel",
						disabled: false
					}
				]
			},
			maintenance: {
				type: "maintenance",
				header: "Запись на плановое ТО",
				body: [
					{
						id: 0,
						kind: "single",
						name: "contract",
						title: "Номер договора",
						value: "№А-5644001 от 13.12.2021",
						status: "default",
						error: false,
						errorMessage: "Неверный формат",
						disabled: true
					},
					{
						id: 1,
						kind: "single",
						name: "address",
						title: "Адрес системы",
						value: "г. Бобалево, ул. Маневровая 156, кв 8",
						status: "default",
						error: false,
						errorMessage: "Неверный формат",
						disabled: true
					},
					{
						id: 2,
						kind: "double",
						first: {
							name: "date",
							type: "input",
							title: "Срок уплаты",
							value: "01.05.2022",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						},
						last: {
							name: "cost",
							type: "input",
							title: "Стоимость",
							value: "1 389 руб. 09 коп.",
							status: "default",
							error: false,
							errorMessage: "Неверный формат",
							disabled: true
						}
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Записаться",
						paint: "blue",
						action: "getMaintenance",
						disabled: false
					},
					{
						id: 1,
						type: "text",
						value: "Отмена",
						paint: "gray",
						action: "cancel",
						disabled: false
					}
				]
			},
			callback: {
				type: "callback",
				header: "Связь с техподдержкой",
				body: [
					{
						id: 0,
						kind: "single",
						name: "phone",
						title: "Телефон для связи",
						value: null,
						status: "default",
						error: false,
						errorMessage: "Неверный формат"
					}
				],
				footer: [
					{
						id: 0,
						type: "text",
						value: "Заказать звонок",
						paint: "blue",
						action: "getCallback",
						disabled: true
					}
				]
			},
			params: null,
			visible: false,
			cancellation: true,
			validation: false
		},
		headers: {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("authorization__user-token") || null}`
			}
		},
		preloader: false
	},
	getters: {
		getCommonContent: state => state.content,
		getCommonModal: state => state.modal,
		getCommonPreloader: state => state.preloader
	},
	mutations: {
		showPreloader(state) {
			state.preloader = true;
		},
		hidePreloader(state) {
			state.preloader = false;
		},
		setHeaders(state, payload) {
			state.headers = {
				headers: {
					Authorization: `Bearer ${payload}`
				}
			};
		},
		removeHeaders(state) {
			state.headers = {};
		},
		handleValidation(state, module) {
			module.state[module.payload.name].value = module.payload.value;

			if (module.payload.page === "reset") {
				if (module.payload.name === "password") {
					if (!module.payload.value.match(state.patterns[module.payload.name])) {
						module.state[module.payload.name].status = "invalid";
						module.state[module.payload.name].error =
							"Не менее 6 символов, строчные и заглавные буквы, цифры";
					} else if (
						module.payload.value.match(state.patterns[module.payload.name]) &&
						module.payload.value !== module.state.confirm.value &&
						module.state.confirm.value !== null
					) {
						module.state[module.payload.name].status = "invalid";
						module.state[module.payload.name].error = "Пароли не совпадают";
					} else {
						module.state[module.payload.name].status = "valid";
						module.state[module.payload.name].error = null;
					}
				}

				if (module.payload.name === "confirm") {
					if (!module.payload.value.match(state.patterns[module.payload.name])) {
						module.state[module.payload.name].status = "invalid";
						module.state[module.payload.name].error =
							"Не менее 6 символов, строчные и заглавные буквы, цифры";
					} else if (
						module.payload.value.match(state.patterns[module.payload.name]) &&
						module.payload.value !== module.state.password.value
					) {
						module.state[module.payload.name].status = "invalid";
						module.state[module.payload.name].error = "Пароли не совпадают";
					} else {
						module.state[module.payload.name].status = "valid";
						module.state[module.payload.name].error = null;
					}
				}

				if (
					module.state.password.value === module.state.confirm.value &&
					module.state.password.value.match(state.patterns[module.payload.name]) &&
					module.state.confirm.value.match(state.patterns[module.payload.name])
				) {
					module.state.password.status = "valid";
					module.state.password.error = null;
					module.state.confirm.status = "valid";
					module.state.confirm.error = null;
				}
			} else {
				if (module.payload.value.match(state.patterns[module.payload.name])) {
					module.state[module.payload.name].status = "valid";
					module.state[module.payload.name].error = null;
				} else {
					module.state[module.payload.name].status = "invalid";
					module.state[module.payload.name].error = "Неверный формат";
				}
			}

			module.state.validation = true;
			if (module.state.cancellation) module.state.cancellation = false;

			for (let name in module.state) {
				if (module.state[name] && module.state[name].status !== undefined) {
					if (module.state[name].status !== "valid") module.state.validation = false;
				}
			}
		},
		handleError(state, error) {
			state.preloader = false;

			if (error.response === undefined) router.push("/login");

			let payload = {
				type: "error",
				params: {
					text: error.response ? error.response.data.error.message : error
				}
			};

			this.commit("createPopup", payload);

			console.log(error);
		},
		createModal(state, payload) {
			state.modal.params = payload.params;

			if (payload.type === "callback") {
				state.modal.callback.body.forEach(element => {
					if (element.name === payload.params.name && payload.params.value !== null) {
						element.value = payload.params.value;
						element.status = "valid";
						state.modal.callback.footer[0].disabled = false;
					}
				});
			}

			state.modal.current = JSON.parse(JSON.stringify(state.modal[payload.type]));
			state.modal.visible = true;
		},
		changeModal(state, payload) {
			state.modal.current.body.forEach(element => {
				if (payload.event === "focus") {
					if (payload.kind === "setup") {
						if (element.id === payload.id) {
							if (isNaN(+payload.value.slice(0, -1))) element[payload.part].value = "";
							else element[payload.part].value = +payload.value.slice(0, -1);

							element[payload.part].unit = "";
						}
					}
				}

				if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
					if (payload.kind === "single") {
						if (element.id === payload.id) {
							if (payload.source === "dropdown") {
								if (payload.open) {
									for (let key in state.modal.params) {
										if (key === "num") {
											let common = [...Array(31)].map((el, i) => {
												return {
													id: i + 1,
													name: i + 1,
													title: `Зона ${i + 1}`
												};
											});

											let arr = this.state.system.zones;

											for (let i = 0; i < arr.length; i++) {
												for (let j = 0; j < common.length; j++) {
													if (common[j].id === arr[i].num.val) {
														common.splice(common.indexOf(common[j]), 1);
													}
												}
											}

											state.modal.current.body[1].dropdown.categories = common;
										}
									}
								} else {
									element.dropdown.value = payload.value;

									if (payload.type == "zone") element.value = +payload.name;
									else element.value = payload.name;

									if (payload.value !== "") {
										element.status = "valid";
										element.dropdown.status = "valid";
										element.dropdown.error = false;
									} else {
										element.status = "invalid";
										element.dropdown.status = "invalid";
										element.dropdown.error = true;
									}

									for (let key in state.modal.params) {
										if (element.name === key) {
											state.modal.params[key] = element.value;
										}
									}
								}
							} else {
								element.value = payload.value;

								if (payload.name === "password_new" || payload.name === "password_confirm") {
									if (payload.name === "password_new") {
										if (!payload.value.match(state.patterns[payload.name])) {
											element.status = "invalid";
											element.error = true;
											element.errorMessage =
												"Не менее 6 символов, строчные и заглавные буквы, цифры";
										} else if (
											payload.value.match(state.patterns[payload.name]) &&
											payload.value !== state.modal.current.body[2].value &&
											state.modal.current.body[2].value !== null
										) {
											element.status = "invalid";
											element.error = true;
											element.errorMessage = "Пароли не совпадают";
										} else {
											element.status = "valid";
											element.error = false;
										}
									}

									if (payload.name === "password_confirm") {
										if (!payload.value.match(state.patterns[payload.name])) {
											element.status = "invalid";
											element.error = true;
											element.errorMessage =
												"Не менее 6 символов, строчные и заглавные буквы, цифры";
										} else if (
											payload.value.match(state.patterns[payload.name]) &&
											payload.value !== state.modal.current.body[1].value
										) {
											element.status = "invalid";
											element.error = true;
											element.errorMessage = "Пароли не совпадают";
										} else {
											element.status = "valid";
											element.error = false;
										}
									}

									if (
										state.modal.current.body[1].value === state.modal.current.body[2].value &&
										state.modal.current.body[1].value.match(state.patterns.password_new) &&
										state.modal.current.body[2].value.match(state.patterns.password_confirm)
									) {
										state.modal.current.body[1].status = "valid";
										state.modal.current.body[1].error = false;
										state.modal.current.body[2].status = "valid";
										state.modal.current.body[2].error = false;
									}
								} else {
									if (payload.value.match(state.patterns[payload.name])) {
										element.status = "valid";
										element.error = false;
									} else {
										element.status = "invalid";
										element.error = true;
									}
								}

								for (let key in state.modal.params) {
									if (element.name === key) {
										state.modal.params[key] = element.value;
									}
								}
							}
						}
					}

					if (payload.kind === "double") {
						if (element.id === payload.id) {
							if (payload.source === "datepicker") {
								if (isNaN(payload.value)) element[payload.part].value = null;
								else element[payload.part].value = payload.value;

								if (element[payload.part].value !== null) {
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								} else {
									element[payload.part].status = "invalid";
									element[payload.part].error = true;
								}
							} else {
								element[payload.part].value = payload.value;

								if (payload.value.match(state.patterns[payload.name])) {
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								} else {
									element[payload.part].status = "invalid";
									element[payload.part].error = true;
								}
							}

							if (element.first.status === "valid" && element.last.status === "valid")
								element.status = "valid";
							else element.status = "invalid";

							for (let key in state.modal.params) {
								if (element[payload.part].name === key) {
									state.modal.params[key] = element[payload.part].value;
								}
							}
						}
					}

					if (payload.kind === "setup") {
						if (element.id === payload.id) {
							if (payload.source === "switch") {
								element[payload.part].disabled = !payload.value;

								if (payload.value === false) {
									element[payload.part].value = element[payload.part].default;
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								}

								for (let key in state.modal.params) {
									if (element[payload.part].name === key) {
										state.modal.params[key].status = !element[payload.part].disabled;
										state.modal.params[key].setting = element[payload.part].value;
										state.modal.params[key].current = element[payload.part].value;
									}
								}
							}
							if (payload.source === "input") {
								element[payload.part].value = payload.value;

								if (payload.value.match(state.patterns[payload.name])) {
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								} else {
									element[payload.part].status = "invalid";
									element[payload.part].error = true;
								}

								for (let key in state.modal.params) {
									if (element[payload.part].name === key) {
										state.modal.params[key].setting = +element[payload.part].value;
										state.modal.params[key].current = +element[payload.part].value;
									}
								}
							}

							if (element.first.status === "invalid" || element.last.status === "invalid") {
								element.status = "invalid";
							} else {
								element.status = "valid";
							}
						}
					}

					if (payload.kind === "module") {
						if (element.id === payload.id) {
							if (payload.source === "dropdown") {
								element.dropdown.value = payload.value;
								element.dropdown.alias = payload.name;

								if (payload.value !== "") {
									element.dropdown.status = "valid";
									element.dropdown.error = false;
								} else {
									element.dropdown.status = "invalid";
									element.dropdown.error = true;
								}
							}

							if (payload.source === "input") {
								element.input.value = payload.value;

								if (payload.value.match(state.patterns[payload.name])) {
									element.input.status = "valid";
									element.input.error = false;
								} else {
									element.input.status = "invalid";
									element.input.error = true;
								}
							}

							if (payload.source === "add") {
								let obj = {
									first: {
										value: element.dropdown.value
									},
									last: {
										value: element.input.value
									}
								};

								element.modules.push(obj);

								if (element.dropdown.name === "device") {
									let obj2 = {
										type: element.dropdown.alias,
										addr: +element.input.value
									};
									state.modal.params.devs.push(obj2);
								}

								if (element.dropdown.name === "nozzle") {
									let obj3 = {
										type: element.dropdown.alias,
										quan: +element.input.value
									};
									state.modal.params.nozzles.push(obj3);
								}

								element.dropdown.value = "";
								element.dropdown.status = "default";
								element.input.value = "";
								element.input.status = "default";
								element.button.disabled = true;
							}

							if (payload.source === "delete") {
								element.modules = element.modules.filter((element, index) => index !== payload.index);

								if (element.dropdown.name === "device") {
									state.modal.params.devs = state.modal.params.devs.filter(
										(element, index) => index !== payload.index
									);
								}
								if (element.dropdown.name === "nozzle") {
									state.modal.params.nozzles = state.modal.params.nozzles.filter(
										(element, index) => index !== payload.index
									);
								}
							}

							if (element.dropdown.status === "valid" && element.input.status === "valid") {
								element.button.disabled = false;
							} else {
								element.button.disabled = true;
							}

							if (element.dropdown.status !== "invalid" && element.input.status !== "invalid") {
								element.status = "valid";
							} else {
								element.status = "invalid";
							}
						}
					}

					if (payload.kind === "set") {
						if (element.id === payload.id) {
							if (payload.source === "dropdown") {
								element.dropdown.value = payload.value;
								element.value = payload.name;

								if (payload.value !== "") {
									element.status = "valid";
									element.dropdown.status = "valid";
									element.dropdown.error = false;
								} else {
									element.status = "invalid";
									element.dropdown.status = "invalid";
									element.dropdown.error = true;
								}

								for (let key in state.modal.params) {
									if (element.name === key) {
										state.modal.params[key] = element.value;
									}
								}
							}

							let start = state.modal.current.body.indexOf(element) + 1;
							let data = element.set[payload.name];

							state.modal.current.body.splice(start, state.modal.current.body.length);
							data.forEach(elem => state.modal.current.body.push(elem));
						}
					}

					if (payload.kind === "triple") {
						if (element.id === payload.id) {
							if (payload.source === "picker") {
								element[payload.part].value = payload.value;

								if (element[payload.part].value !== "") {
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								} else {
									element[payload.part].status = "invalid";
									element[payload.part].error = true;
								}

								for (let key in state.modal.params) {
									if (element[payload.part].name === key) {
										state.modal.params[key] = payload.value;
									}
								}
							}

							if (payload.source === "cvc") {
								element[payload.part].value = payload.value;

								if (payload.value.match(state.patterns[payload.name])) {
									element[payload.part].status = "valid";
									element[payload.part].error = false;
								} else {
									element[payload.part].status = "invalid";
									element[payload.part].error = true;
								}

								for (let key in state.modal.params) {
									if (element[payload.part].name === key) {
										state.modal.params[key] = payload.value;
									}
								}
							}

							if (
								element.first.status === "valid" &&
								element.middle.status === "valid" &&
								element.last.status === "valid"
							)
								element.status = "valid";
							else element.status = "invalid";
						}
					}

					if (payload.kind === "file") {
						if (element.id === payload.id) {
							if (payload.source === "input") {
								element.input.value = payload.value;

								if (payload.value.match(state.patterns[payload.name])) {
									element.input.status = "valid";
									element.input.error = false;
								} else {
									element.input.status = "invalid";
									element.input.error = true;
								}

								for (let key in state.modal.params) {
									if (element.input.name === key) {
										state.modal.params[key] = payload.value;
									}
								}
							}

							if (payload.source === "file") {
								element.button.value = "apply";
								state.modal.params.file = payload.value;
							}

							if (element.input.status === "valid" && element.button.value === "apply")
								element.status = "valid";
							else element.status = "invalid";
						}
					}
				}

				if (payload.event === "blur") {
					if (payload.kind === "setup") {
						if (element.id === payload.id) {
							if (payload.name === "humidity") element[payload.part].unit = "%";
							if (payload.name === "temp") element[payload.part].unit = "°";
						}
					}
				}
			});

			state.modal.current.footer[0].disabled = !state.modal.current.body.every(
				element => element.status == "valid"
			);
		},
		validateModal(state) {
			console.log(state.modal.validation);
		},
		destroyModal(state) {
			state.modal.current = null;
			state.modal.params = null;
			state.modal.visible = false;
			state.modal.cancellation = true;
			state.modal.validation = false;
		}
	},
	actions: {}
};
