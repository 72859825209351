<template>
	<div class="menu">
		<router-link
			v-for="(page, index) in content.pages"
			class="menu__link"
			active-class="menu__link_active"
			exact-active-class=""
			:key="index"
			:to="page.path"
		>
			<div class="menu__icon"><font-awesome-icon :icon="page.icon" /></div>
			<div class="menu__title">{{ page.title }}</div>
		</router-link>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Menu",
	computed: {
		...mapGetters({
			content: "getCommonContent"
		})
	},
	methods: {}
};
</script>

<style lang="scss">
.menu {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	border: 2px solid #b0b0b0;
	padding: 5px;
	margin-right: 10px;

	@include mediaquery(lg) {
		display: flex;
		padding: 10px;
		margin-right: 0;
	}

	&__link {
		height: 40px;
		display: flex;
		align-items: center;
		padding: 2px;
		text-decoration: none;
		color: #8c8c8c;

		@include mediaquery(lg) {
			padding: 5px;
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&_active {
			color: #1768c7;
		}
	}

	&__icon {
		font-size: 18px;
		width: 25px;
		text-align: center;
	}

	&__title {
		margin-left: 10px;
		font-size: 14px;
		display: none;

		@include mediaquery(lg) {
			display: block;
		}
	}
}
</style>
