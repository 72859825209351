<template>
	<div class="profile">
		<main class="profile__main">
			<div class="profile__content">
				<h2 class="profile__headline">Профиль</h2>
				<div class="profile__notification">
					<div class="profile__notification-wrapper">
						<div class="profile__notification-total">
							<div class="profile__notification-amount">30%</div>
							<div class="profile__notification-progress">
								<span class="profile__notification-bar profile__notification-bar"></span>
							</div>
						</div>
						<div class="profile__notification-info">
							<div class="profile__notification-title">Редактирование профиля</div>
							<div class="profile__notification-text">
								Заполните свой профиль для получения дополнительных возможностей
							</div>
						</div>
					</div>
					<button class="profile__notification-button">Редактировать</button>
				</div>
				<div class="profile__body">
					<div class="profile__sidebar">
						<img
							class="profile__sidebar-photo"
							:src="require('../assets/images/profile__photo.png')"
							alt=""
						/>
						<div class="profile__sidebar-name">Иванова Ольга Сергеевна</div>
						<div class="profile__sidebar-position">Врач-терапевт</div>
						<div class="profile__sidebar-role">Роль в РО: активист</div>
						<div class="profile__sidebar-rating">4.5</div>
						<div class="profile__sidebar-stars">
							<font-awesome-icon :icon="['fas', 'star']" />
							<font-awesome-icon :icon="['fas', 'star']" />
							<font-awesome-icon :icon="['fas', 'star']" />
							<font-awesome-icon :icon="['fas', 'star']" />
							<font-awesome-icon :icon="['fas', 'star']" />
						</div>

						<div class="profile__sidebar-control">
							<button class="profile__sidebar-button">
								<font-awesome-icon :icon="['fas', 'user']" /> Личная информация
							</button>
							<button class="profile__sidebar-button">
								<font-awesome-icon :icon="['fas', 'chart-simple']" /> Активность
							</button>
							<button class="profile__sidebar-button">
								<font-awesome-icon :icon="['fas', 'lock']" /> Смена пароля
							</button>
							<button class="profile__sidebar-button">
								<font-awesome-icon :icon="['fas', 'gear']" /> Настройки
							</button>
						</div>
					</div>
					<div class="profile__holder">
						<!-- <div class="profile__holder-title">Личная информация</div> -->
						<ul class="profile__features">
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'handshake-angle']" /> Мои проблемы (0)
							</li>
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'person-digging']" /> Моя занятость (0)
							</li>
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'bell-concierge']" /> Мои услуги (2)
							</li>
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'store']" /> Мои товары (1)
							</li>
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'diagram-project']" /> Мои проекты (0)
							</li>
							<li class="profile__feature">
								<font-awesome-icon :icon="['fas', 'wallet']" /> Мои платежи (13)
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>
<script>
export default {
	name: "Profile"
};
</script>

<style lang="scss" scoped>
.profile {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;

		color: #8c8c8c;
		font-size: 16px;
	}

	&__headline {
		@include headline;
	}

	&__notification {
		border-radius: 10px;
		border: 2px solid #b0b0b0;
		padding: 15px;
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			justify-content: space-between;
		}

		&-wrapper {
			display: flex;

			flex-direction: column;

			@include mediaquery(md) {
				flex-direction: row;
				align-items: flex-end;
				justify-content: space-between;
			}
		}

		&-title {
			color: #1768c7;
			font-size: 20px;
			font-weight: 500;
			margin: 10px 0;

			@include mediaquery(md) {
				margin: 0;
			}
		}

		&-text {
			color: #cacad8;
			font-size: 16px;
		}

		&-total {
			width: 100px;
			margin-right: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&-progress {
			width: 100%;
			height: 7px;
			border-radius: 2px;
			background: #ededed;
			position: relative;
		}

		&-amount {
			color: #1768c7;
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 10px;
		}

		&-bar {
			display: inline-block;
			background-color: #1768c7;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 2px;
			width: 30%;
		}

		&-button {
			background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
			border: 0;
			border-radius: 10px;
			color: #fff;
			font-size: 16px;
			padding: 10px;
			margin-top: 10px;
			cursor: pointer;

			@include mediaquery(md) {
				margin: 0;
			}
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		margin-top: 10px;

		@include mediaquery(md) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__sidebar {
		border-radius: 10px;
		border: 2px solid #b0b0b0;
		padding: 35px 15px 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 10px;

		@include mediaquery(md) {
			width: 350px;
			margin-right: 10px;
			margin-bottom: 0;
		}

		&-photo {
			width: 150px;
			border-radius: 10px;
			border: 2px solid #1768c7;
		}

		&-name {
			color: #2177dc;
			text-align: center;
			font-size: 18px;
			font-weight: 500;
			margin-top: 13px;
		}

		&-position {
			color: #8c8c8c;
			font-size: 16px;
			margin-top: 7px;
		}

		&-role {
			font-size: 16px;
			font-weight: 500;
			margin-top: 10px;
		}

		&-rating {
			font-size: 26px;
			margin-top: 10px;
			color: #2177dc;
			font-weight: 500;
			margin-bottom: 5px;
		}

		&-stars {
			color: #2177dc;
		}

		&-control {
			margin-top: 34px;
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		&-button {
			background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
			border: 0;
			border-radius: 10px;
			color: #fff;
			font-size: 14px;
			padding: 10px 20px;
			cursor: pointer;
			margin-bottom: 10px;
			display: flex;
			align-items: center;

			&:last-of-type {
				margin-bottom: 0;
			}

			svg {
				margin-right: 15px;
			}
		}
	}

	&__holder {
		border-radius: 10px;
		border: 2px solid #b0b0b0;
		padding: 15px;
		display: flex;
		width: 100%;
	}

	&__features {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__feature {
		display: flex;
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		cursor: pointer;
		margin-bottom: 10px;

		svg {
			margin-right: 10px;
		}
	}
}
</style>
