<template>
  <div class="modal" v-if="modal.visible">
    <div class="modal__window">
      <button class="modal__close" @click="handleClick">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </button>
      <div class="modal__header">Редактирование распорядка дня</div>
      <div class="modal__body">
        <div class="modal__block">
          <input type="text" />
        </div>
      </div>
      <div class="modal__footer">
        <button>Отменить</button>
        <button>Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  export default {
    name: "Modal",
    components: {},
    computed: {
      ...mapGetters({
        modal: "getCommonModal",
      }),
    },
    methods: {
      ...mapMutations(["destroyModal", "changeModal", "validateModal"]),
      ...mapActions([
        "addProfileContact",
        "addProfileUser",
        "addProfileCard",
        "changeSystemOwner",
        "addSystemDocument",
        "addSystemZone",
        "createSystemsObject",
        "editSystemsObject",
        "changeProfilePassword",
        "getCommonCallback",
      ]),
      handleChange(event, source) {
        let payload = {};
        let kind = event.target
          .closest(".modal__block")
          .getAttribute("data-kind");

        if (source === "input" || source === "email") {
          payload = {
            name: event.target.name,
            value: event.target.value,
            event: event.type,
          };

          if (kind === "setup" || kind === "double") {
            payload.part = event.target
              .closest(".modal__subblock")
              .getAttribute("data-part");
          }
        }

        if (source === "phone") {
          if (event.type === "focus" && event.target.value == "") {
            event.target.value = "+7 ";
          } else if (event.type === "paste") {
            let value = event.clipboardData.getData("text/plain");

            let pattern =
              /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
            let phoneNumbers = value.match(pattern);

            if (phoneNumbers !== null) {
              let correctNumber = phoneNumbers[0].replace(
                pattern,
                "+7 ($2) $3-$4-$5"
              );

              event.target.value = correctNumber;
            }
          }

          if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            if (event.target.selectionStart < 3) {
              event.target.value = `+7 ${event.data}`;
            }

            let mask = "+7 (___) ___-__-__";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              index < 5 && (index = 3);
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");

            reg = new RegExp(`^${reg}$`);

            if (
              !reg.test(event.target.value) ||
              event.target.value.length < 5 ||
              (keyCode > 47 && keyCode < 58)
            ) {
              event.target.value = newVal;
            }
          }

          payload = {
            name: event.target.name,
            value: event.target.value,
            event: event.type,
          };

          if (kind === "setup" || kind === "double") {
            payload.part = event.target
              .closest(".modal__subblock")
              .getAttribute("data-part");
          }
        }

        if (source === "number") {
          if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            let mask = "____ ____ ____ ____";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");
            reg = new RegExp(`^${reg}$`);

            if (!reg.test(event.target.value) || (keyCode > 47 && keyCode < 58)) {
              event.target.value = newVal;
            }
          }

          payload = {
            name: event.target.name,
            value: event.target.value,
            event: event.type,
          };
        }

        if (source === "password") {
          if (event.type === "focus" && event.target.value === "") {
            event.target.value = this.generatePassword(10);
          }

          payload = {
            name: event.target.name,
            value: event.target.value,
            event: "change",
          };
        }

        if (source === "cvc") {
          if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            let mask = "___";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");
            reg = new RegExp(`^${reg}$`);

            if (!reg.test(event.target.value) || (keyCode > 47 && keyCode < 58)) {
              event.target.value = newVal;
            }
          }

          payload = {
            name: event.target.name,
            value: event.target.value,
            part: event.target
              .closest(".modal__subblock")
              .getAttribute("data-part"),
            event: event.type,
          };
        }

        if (source === "holder") {
          if (event.type === "keydown" || event.type === "input") {
            let val = event.target.value.replace(/[^a-zA-Z\s]/g, "");
            event.target.value = val.toUpperCase();
          }

          payload = {
            name: event.target.name,
            value: event.target.value,
            event: event.type,
          };
        }

        if (source === "datepicker") {
          payload = {
            name: "date",
            value: event.value,
            event: "change",
          };

          if (kind === "setup" || kind === "double") {
            payload.part = event.target
              .closest(".modal__subblock")
              .getAttribute("data-part");
          }
        }

        if (source === "dropdown") {
          if (
            event.target.closest(".modal").getAttribute("data-type") === "zone"
          ) {
            if (
              event.target.closest(".dropdown__button") ||
              event.target.closest(".dropdown__field")
            ) {
              payload = {
                event: "change",
                open: true,
              };
            } else if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
                open: false,
              };
            } else return false;
          } else if (
            event.target.closest(".dropdown").getAttribute("data-type") ===
            "multi"
          ) {
            if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown")
                  .querySelector(".dropdown__field-text").innerHTML,
                event: "change",
                multi: true,
                open: false,
              };
            } else return false;
          } else {
            if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
                open: false,
              };
            } else return false;
          }
        }

        if (source === "picker") {
          if (event.target.closest(".picker__item")) {
            payload = {
              value: event.target.closest(".picker__item").innerHTML,
              part: event.target
                .closest(".modal__subblock")
                .getAttribute("data-part"),
              event: "change",
            };
          } else return false;
        }

        if (source === "file") {
          if (event.target.closest(".modal__file")) {
            payload = {
              value: event.target.files[0],
              event: event.type,
            };
          } else return false;
        }

        if (source === "switch") {
          payload = {
            value: event.target.checked,
            part: event.target
              .closest(".modal__subblock")
              .getAttribute("data-part"),
            event: event.type,
          };
        }

        if (source === "add") {
          payload = {
            index: +event.target.closest(".modal__block").getAttribute("id"),
            event: "change",
          };
        }

        if (source === "delete") {
          payload = {
            index: +event.target
              .closest(".modal__module")
              .getAttribute("data-id"),
            event: "change",
          };
        }

        payload.type = event.target.closest(".modal").getAttribute("data-type");
        payload.kind = kind;
        payload.source = source;
        payload.id = +event.target.closest(".modal__block").id;

        this.changeModal(payload);
      },
      handleClick(action) {
        if (action === "addContact") {
          this.addProfileContact(this.modal.params);
        }

        if (action === "addUser") {
          this.addProfileUser(this.modal.params);
        }

        if (action === "addCard") {
          this.addProfileCard(this.modal.params);
        }

        if (action === "changeOwner") {
          this.changeSystemOwner(this.modal.params);
        }

        if (action === "addDocument") {
          this.addSystemDocument(this.modal.params);
        }

        if (action === "addZone") {
          this.addSystemZone(this.modal.params);
        }

        if (action === "createObject") {
          this.createSystemsObject(this.modal.params);
        }

        if (action === "editObject") {
          this.editSystemsObject(this.modal.params);
        }

        if (action === "changePassword") {
          this.changeProfilePassword(this.modal.params);
        }

        if (action === "getCallback") {
          this.getCommonCallback(this.modal.params);
        }

        this.destroyModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background-color: rgba(240, 240, 240, 0.8);
    z-index: $zIndexModal;
    padding: 50px 0;

    &__window {
      background-color: #fff;
      position: relative;
      width: calc(100% - 60px);
      margin: 0 auto;

      @include mediaquery(sm) {
        width: 400px;
      }
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      font-size: 16px;
      color: #fff;
    }

    &__header {
      font-size: 16px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      background: #2183a6;
      padding: 20px;
    }

    &__body {
      font-size: 14px;
      margin: 20px 0;
      color: #2b1a0a;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include mediaquery(sm) {
        flex-direction: row;

        button {
          margin-bottom: 0;

          &:first-of-type {
            width: 180px;
          }

          &:last-of-type {
            width: 110px;
          }

          &:only-child {
            width: 100%;
          }
        }
      }
    }
  }
</style>
