<template>
	<div class="projects">
		<main class="projects__main">
			<div class="projects__content">
				<h2 class="projects__headline">Проекты</h2>
				<div class="projects__intro">
					<div class="projects__preface">
						Данный раздел создан для коллективного финансирования проектов, при котором деньги на создание
						продукта поступают от его конечных потребителей. Вы можете предложить свой проект, нажав на
						кнопку "Создать".
					</div>
					<button class="projects__request">Создать</button>
				</div>
				<h3 class="projects__caption">Активные проекты</h3>
				<ul class="projects__list">
					<li class="projects__item">
						<div class="projects__item-icon"><font-awesome-icon :icon="['fas', 'dumbbell']" /></div>
						<div class="projects__item-category">Спорт</div>
						<div class="projects__item-description">
							Организация бесплатных тренировок по единоборствам для детей
						</div>
						<div class="projects__item-progress">
							<span class="projects__item-bar projects__item-bar_sport"></span>
						</div>
						<div class="projects__item-wrapper">
							<div class="projects__item-state">
								<span class="projects__item-result">95%</span>
								<span class="projects__item-title">Идет сбор</span>
							</div>
							<div class="projects__item-amount">
								<span class="projects__item-result">245 000 руб.</span>
								<span class="projects__item-title">Собрано</span>
							</div>
						</div>
						<button class="projects__item-donate">Поддержать</button>
					</li>
					<li class="projects__item">
						<div class="projects__item-icon"><font-awesome-icon :icon="['fas', 'utensils']" /></div>
						<div class="projects__item-category">Питание</div>
						<div class="projects__item-description">Создание сети уличного питания русской кухни</div>
						<div class="projects__item-progress">
							<span class="projects__item-bar projects__item-bar_food"></span>
						</div>
						<div class="projects__item-wrapper">
							<div class="projects__item-state">
								<span class="projects__item-result">42%</span>
								<span class="projects__item-title">Идет сбор</span>
							</div>
							<div class="projects__item-amount">
								<span class="projects__item-result">67 200 руб.</span>
								<span class="projects__item-title">Собрано</span>
							</div>
						</div>
						<button class="projects__item-donate">Поддержать</button>
					</li>
					<li class="projects__item">
						<div class="projects__item-icon"><font-awesome-icon :icon="['fas', 'shirt']" /></div>
						<div class="projects__item-category">Мерч</div>
						<div class="projects__item-description">Пошив одежды под собственной маркой</div>
						<div class="projects__item-progress">
							<span class="projects__item-bar projects__item-bar_clothes"></span>
						</div>
						<div class="projects__item-wrapper">
							<div class="projects__item-state">
								<span class="projects__item-result">13%</span>
								<span class="projects__item-title">Идет сбор</span>
							</div>
							<div class="projects__item-amount">
								<span class="projects__item-result">32 465 руб.</span>
								<span class="projects__item-title">Собрано</span>
							</div>
						</div>
						<button class="projects__item-donate">Поддержать</button>
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Projects"
};
</script>

<style lang="scss" scoped>
.projects {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}

	&__caption {
		font-size: 20px;
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;

		@include mediaquery(md) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__item {
		border-radius: 5px;
		border: 2px solid #aeaeae;
		padding: 15px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include mediaquery(md) {
			width: calc(33.333333% - 10px);
			margin-bottom: 0;
		}

		&-icon {
			font-size: 40px;
			color: #8c8c8c;
		}

		&-category {
			color: #8c8c8c;
			font-size: 20px;
			margin-top: 10px;
		}

		&-description {
			margin: 10px 0;
			font-size: 16px;
		}

		&-progress {
			width: 100%;
			height: 7px;
			border-radius: 2px;
			background: #ededed;
			position: relative;
		}

		&-bar {
			display: inline-block;
			background-color: #1768c7;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 2px;

			&_sport {
				width: 95%;
			}

			&_food {
				width: 42%;
			}

			&_clothes {
				width: 13%;
			}
		}

		&-wrapper {
			width: 100%;
			margin: 10px 0 20px;
			display: flex;
			justify-content: space-between;
		}

		&-state,
		&-amount {
			display: flex;
			flex-direction: column;
		}

		&-amount {
			align-items: flex-end;
		}

		&-title {
			text-transform: uppercase;
			color: #8c8c8c;
		}

		&-result {
			font-size: 22px;
			color: #1768c7;
		}

		&-donate {
			background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
			border: 0;
			border-radius: 10px;
			color: #fff;
			font-size: 16px;
			padding: 10px;
			width: 100%;
			cursor: pointer;
		}
	}
}
</style>
