<template>
  <div class="preloader" v-if="preloader">
    <img class="preloader__image" src="../assets/images/preloader.svg" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Preloader",
    computed: {
      ...mapGetters({
        preloader: "getCommonPreloader",
      }),
    },
  };
</script>

<style lang="scss">
  .preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 248, 248, 0.9);
    opacity: 1;
    z-index: $zIndexPreloader;
    display: flex;
    align-items: center;
    justify-content: center;

    &_invisible {
      background-color: lighten($colorLight, 100%);
      opacity: 0;
      z-index: -1;
      transition: opacity ease-out 0.2s, background-color 0.2s, z-index 0.2s;

      .popup__image {
        transform: scale(0);
      }
    }

    &__image {
      display: block;
      position: relative;
      height: 100px;
      width: 100px;
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
    }
  }
</style>
