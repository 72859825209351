<template>
	<div class="easter">
		<main class="easter__main">
			<div class="easter__content">
				<h2 class="easter__headline">Пасха</h2>
				<div class="easter__intro">
					<div class="easter__preface">
						Адреса участников мероприятия
					</div>
				</div>
				<div class="easter__map" id="map"></div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Easter",
	created() {
		let DG = require("2gis-maps");
		let map = document.querySelector("#map");

		let objects = [
			{
				latitude: 56.859306,
				longitude: 60.644447,
				address: "Ул. Июльская, 16, кв. 23"
			},
			{
				latitude: 56.863129,
				longitude: 60.642184,
				address: "Ул. Садовая, 9, кв. 172"
			},
			{
				latitude: 56.862898,
				longitude: 60.649155,
				address: "Ул. Боровая, 24, кв. 35"
			},
			{
				latitude: 56.859817,
				longitude: 60.645669,
				address: "Ул. Советская, 53, кв. 98"
			},
			{
				latitude: 56.861545,
				longitude: 60.632796,
				address: "Ул. Учителей, 4, кв. 45"
			}
		];

		DG.then(function() {
			map = DG.map("map", {
				center: [56.837293, 60.597807],
				zoom: 11
			});

			let markers = DG.featureGroup();

			for (let i = 0; i < objects.length; i++) {
				let icon = DG.icon({
					iconUrl: require("../assets/images/icon-egg.png"),
					iconSize: [25, "auto"],
					iconAnchor: [12, 16]
				});

				DG.marker([objects[i].latitude, objects[i].longitude], {
					icon: icon
				})
					.addTo(map)
					.bindPopup(`${objects[i].address}`);
			}

			markers.addTo(map);
		});
	}
};
</script>

<style lang="scss" scoped>
.easter {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
	}

	&__map {
		margin-top: 20px;
		height: 500px;
		border-radius: 5px;
		border: 2px solid #aeaeae;
	}
}
</style>
