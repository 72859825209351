export default {
  state: {
    training: {},
  },
  getters: {
    getTraining: state => state.training,
  },
  mutations: {
    updateTraining(state) {
      state.training = {
        list: [{
            id: 0,
            name: 'Понедельник (Ноги)',
            exercises: [{
                id: 0,
                exercise: 'Приседания со штангой',
                weight: '60 = (20 + 5 + 2) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BF%D1%80%D0%B8%D1%81%D0%B5%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F-%D1%81%D0%BE-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%BE%D0%B9.gif',
              },
              {
                id: 1,
                exercise: 'Выпады',
                weight: '46 = 20 * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%B2%D1%8B%D0%BF%D0%B0%D0%B4%D1%8B-%D1%81%D0%BE-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%BE%D0%B9.gif',
              },
              {
                id: 2,
                exercise: 'Мертвая тяга',
                weight: '74 = (20 + 10 + 2 + 2) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BC%D0%B5%D1%80%D1%82%D0%B2%D0%B0%D1%8F-%D1%82%D1%8F%D0%B3%D0%B0-%D1%81%D0%BE-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%BE%D0%B9.gif',
              }, {
                id: 3,
                exercise: 'Подъем на носки',
                weight: '56 = (20 + 5) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BF%D0%BE%D0%B4%D1%8A%D0%B5%D0%BC-%D0%BD%D0%B0-%D0%BD%D0%BE%D0%BA%D0%B0%D1%85-%D1%81%D0%BE-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%BE%D0%B9.gif',
              }, {
                id: 3,
                exercise: 'Упражнение «книжка»',
                weight: '-',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BA%D0%BD%D0%B8%D0%B6%D0%BA%D0%B0-2.gif',
              }
            ],
          },
          {
            id: 1,
            name: 'Среда (Грудь, плечи)',
            exercises: [{
                id: 0,
                exercise: 'Жим штанги лежа',
                weight: '86 = (20 + 10 + 5 + 5) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%B6%D0%B8%D0%BC-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D0%BB%D0%B5%D0%B6%D0%B0.gif',
              },
              {
                id: 1,
                exercise: 'Пуловер',
                weight: '30',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BF%D0%BE%D0%BB%D0%BE%D0%B2%D0%B5%D1%80-%D1%81-%D0%B3%D0%B0%D0%BD%D1%82%D0%B5%D0%BB%D0%B5%D0%B9.gif',
              },
              {
                id: 2,
                exercise: 'Армейский жим',
                weight: '46 = (10 + 5 + 5) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%B6%D0%B8%D0%BC-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D1%81%D1%82%D0%BE%D1%8F.gif',
              }, {
                id: 3,
                exercise: 'Тяга штанги к подбородку',
                weight: '46 = (10 + 5 + 5) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D1%82%D1%8F%D0%B3%D0%B0-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D0%BA-%D0%BF%D0%BE%D0%B4%D0%B1%D0%BE%D1%80%D0%BE%D0%B4%D0%BA%D1%83.gif',
              }, {
                id: 3,
                exercise: 'Подъем перед собой',
                weight: '30',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BF%D0%BE%D0%B4%D1%8A%D0%B5%D0%BC-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D0%BF%D0%B5%D1%80%D0%B5%D0%B4-%D1%81%D0%BE%D0%B1%D0%BE%D0%B9.gif',
              }
            ],
          },
          {
            id: 0,
            name: 'Пятница (Спина, руки)',
            exercises: [{
                id: 0,
                exercise: 'Подтягивания широким хватом',
                weight: '-',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%9F%D0%BE%D0%B4%D1%82%D1%8F%D0%B3%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D1%88%D0%B8%D1%80%D0%BE%D0%BA%D0%B8%D0%BC-%D1%85%D0%B2%D0%B0%D1%82%D0%BE%D0%BC.gif',
              },
              {
                id: 1,
                exercise: 'Подтягивания обратным хватом',
                weight: '-',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%9F%D0%BE%D0%B4%D1%82%D1%8F%D0%B3%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BE%D0%B1%D1%80%D0%B0%D1%82%D0%BD%D1%8B%D0%BC-%D1%85%D0%B2%D0%B0%D1%82%D0%BE%D0%BC.gif',
              },
              {
                id: 2,
                exercise: 'Тяга штанги в наклоне',
                weight: '66 = (20 + 10) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D1%82%D1%8F%D0%B3%D0%B0-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D0%B2-%D0%BD%D0%B0%D0%BA%D0%BB%D0%BE%D0%BD%D0%B52.gif',
              }, {
                id: 3,
                exercise: 'Отжимания спиной к скамье',
                weight: '-',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BE%D1%82%D0%B6%D0%B8%D0%BC%D0%B0%D0%BD%D0%B8%D1%8F-%D1%81%D0%BF%D0%B8%D0%BD%D0%BE%D0%B9-%D0%BA-%D1%81%D0%BA%D0%B0%D0%BC%D1%8C%D0%B5.gif',
              }, {
                id: 3,
                exercise: 'Подъем штанги на бицепс стоя',
                weight: '40 = (10 + 5 + 2) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D0%BF%D0%BE%D0%B4%D1%8A%D0%B5%D0%BC-%D1%88%D1%82%D0%B0%D0%BD%D0%B3%D0%B8-%D0%BD%D0%B0-%D0%B1%D0%B8%D1%86%D0%B5%D0%BF%D1%81.gif',

              }, {
                id: 4,
                exercise: 'Французский жим сидя',
                weight: '40 = (10 + 5 + 2) * 2 + 6',
                sets: '3',
                reps: '10-12',
                rest: '02:00',
                illustration: 'https://makefitness.pro/wp-content/uploads/2020/02/%D1%84%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D0%BA%D0%B8%D0%B9-%D0%B6%D0%B8%D0%BC-%D1%81%D0%B8%D0%B4%D1%8F.gif',
              }
            ],
          },
        ]
      }
    }
  },
  actions: {
    fetchTraining(context) {
      context.commit("updateTraining");
    },
  }
}