<template>
	<div class="help">
		<main class="help__main">
			<div class="help__content">
				<h2 class="help__headline">Помощь</h2>
				<div class="help__intro">
					<div class="help__preface">
						Данный раздел создан для решения частных несрочных проблем. Вы можете сообщить о своей проблеме,
						нажав на кнопку "Запрос".
					</div>
					<button class="help__request">Запрос</button>
				</div>
				<div class="help__wrapper">
					<h3 class="help__caption">Активные запросы</h3>
					<table class="help__table">
						<thead>
							<tr>
								<th>Дата</th>
								<th>Категория</th>
								<th>Инициатор</th>
								<th>Телефон</th>
								<th>Статус</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>11.11.2023</td>
								<td>Проблемы с автомобмлем</td>
								<td>Сергей</td>
								<td>+7 (900) 345-56-66</td>
								<td class="help__table-td help__table-td_active">Активно</td>
							</tr>
							<tr>
								<td>05.10.2023</td>
								<td>Проблемы с соседями</td>
								<td>Светлана</td>
								<td>+7 (905) 472-55-85</td>
								<td class="help__table-td help__table-td_process">В процессе</td>
							</tr>
							<tr>
								<td>01.10.2023</td>
								<td>Проблемы с компьютером</td>
								<td>Ольга</td>
								<td>+7 (922) 423-92-07</td>
								<td class="help__table-td help__table-td_process">В процессе</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Help"
};
</script>

<style lang="scss" scoped>
.help {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}

	&__caption {
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__wrapper {
		width: 100%;
		overflow-x: scroll;
	}

	&__table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		color: #8c8c8c;

		tbody tr:hover {
			border-color: #1768c7;
			cursor: pointer;
		}

		tr {
			display: flex;
			border-radius: 5px;
			border: 2px solid #aeaeae;
			margin: 8px 0;
		}

		th {
			text-transform: uppercase;
		}

		th,
		td {
			//min-width: 200px;
			padding: 10px;
			text-align: center;
		}

		td {
			font-size: 14px;
		}

		&-td {
			&_active {
				color: #f47fa2;
			}
			&_process {
				color: #2177dc;
			}
		}
	}
}
</style>
