import {
  createStore
} from 'vuex';

import common from './assets/common';
import header from './modules/header';
import product from './pages/product';
import ration from './pages/ration';
import routine from './pages/routine';
import training from './pages/training';

export const store = createStore({
  modules: {
    common,
    header,
    product,
    ration,
    routine,
    training
  },
})