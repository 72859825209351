<template>
	<div class="about">
		<main class="about__main">
			<div class="about__content">
				<h2 class="about__headline">О нас</h2>

				<p class="about__paragraph">
					Русская Община - это платформа или среда для объединения русских людей по принципу взаимопомощи.
					Создание возможностей и сервисов для организации русских общин по территориальному принципу. Для
					помощи друг другу, возрождения русских традиций соборности, общинности, отстаивания законных прав и
					интересов государствообразующего народа и установления горизонтальных связей между общинами.
				</p>
				<p class="about__paragraph">
					Это сетецентричная организация, где каждая община самодостаточна, а координирующий центр оснащает
					ресурсами, сервисами и методиками.
				</p>
				<p class="about__paragraph">
					Миссия Русской Общины - сделать Россию сильным государством. Возродить истинный дух русского народа,
					русскую традицию, культуру и веру.
				</p>
				<p class="about__paragraph">
					Главные цели – организация Русских общин на местах и установление горизонтальных связей между ними,
					развитие русского самоуправления и предпринимательства, единение русских людей для всех видов помощи
					друг другу в жизни, в экстренных ситуациях и посильной постоянной помощи тем, кто в ней нуждается из
					числа членов общины.
				</p>
				<p class="about__paragraph">
					Главные задачи – создание платформы для объединения русских людей, общих принципов объединения,
					рабочих инструментов для самоорганизации и взаимопомощи, среды для создания территориальных общин,
					горизонтальных связей между общинами, обмена опытом, возрождения русских традиций, культуры,
					духовности, укрепления семьи и умножения русского народа, продвижения традиционных ценностей и
					укрепления институтов власти, противодействия деструктивным явлениям, направленным на расчленение
					России и ослабление государства.
				</p>
				<p class="about__paragraph">
					Общие направления деятельности:
				</p>
				<ul class="about__list">
					<li class="about__item">помогать в беде любому члену общины</li>
					<li class="about__item">обеспечивать безопасность русских людей</li>
					<li class="about__item">
						развивать солидарность всех русских организаций в проведении любых мероприятий
					</li>
					<li class="about__item">вести просветительскую деятельность</li>
					<li class="about__item">помогать в трудоустройстве русским людям</li>
					<li class="about__item">продвигать русских в органах власти и силовых структурах</li>
					<li class="about__item">защищать слабых от агрессии криминальных элементов</li>
					<li class="about__item">помогать в начинании нового дела, поддерживать русских предпринимателей</li>
					<li class="about__item">восстанавливать и укреплять русские традиции</li>
					<li class="about__item">возрождать культурные ценности русского народа</li>
					<li class="about__item">развивать спортивную подготовку и укреплять здоровье русских людей</li>
					<li class="about__item">
						добиваться работы представителей органов власти в интересах государствообразующего народа.
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "About"
};
</script>

<style lang="scss" scoped>
.about {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;

		color: #8c8c8c;
		font-size: 16px;
	}

	&__headline {
		@include headline;
	}
}
</style>
