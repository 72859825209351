<template>
	<div class="hitch">
		<main class="hitch__main">
			<div class="hitch__content">
				<h2 class="hitch__headline">Попутка</h2>
				<div class="hitch__intro">
					<div class="hitch__preface">
						В данном разделе можно найти попутчиков для ежедневных, либо единоразовых поездок среди
						общинников. Вы можете предложить свой вариант, нажав на кнопку "Предложить".
					</div>
					<button class="hitch__request">Предложить</button>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Hitch"
};
</script>

<style lang="scss" scoped>
.hitch {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}
}
</style>
