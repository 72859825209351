<template>
	<div class="contacts">
		<main class="contacts__main">
			<div class="contacts__content">
				<h2 class="contacts__headline">Контакты</h2>
				<div class="contacts__intro">
					<div class="contacts__preface">
						Русская Община работает по всей России. Добавляйтесть в группу вашего города и следите за
						новостями.
					</div>
				</div>
				<div class="contacts__map" id="map"></div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Contacts",
	created() {
		let DG = require("2gis-maps");
		let map = document.querySelector("#map");

		let objects = [
			{
				latitude: 55.75586,
				longitude: 37.617698
			},
			{
				latitude: 59.938784,
				longitude: 30.314997
			},
			{
				latitude: 56.838011,
				longitude: 60.597474
			},
			{
				latitude: 55.159902,
				longitude: 61.402554
			},
			{
				latitude: 54.989347,
				longitude: 73.368221
			},
			{
				latitude: 55.030204,
				longitude: 82.92043
			},
			{
				latitude: 56.010569,
				longitude: 92.852572
			},
			{
				latitude: 52.289588,
				longitude: 104.280606
			},
			{
				latitude: 48.480229,
				longitude: 135.071917
			},
			{
				latitude: 57.152985,
				longitude: 65.541227
			},
			{
				latitude: 47.222078,
				longitude: 39.720358
			}
		];

		DG.then(function() {
			map = DG.map("map", {
				center: [66.4167, 94.25],
				zoom: 3
			});

			let markers = DG.featureGroup();

			for (let i = 0; i < objects.length; i++) {
				let icon = DG.icon({
					iconUrl: require("../assets/images/location.svg"),
					iconSize: [30, "auto"],
					iconAnchor: [objects[i].latitude - 40, objects[i].longitude - 50]
				});

				DG.marker([objects[i].latitude, objects[i].longitude], { icon: icon }).addTo(map);
			}

			markers.addTo(map);
		});
	}
};
</script>

<style lang="scss" scoped>
.contacts {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;

		@include mediaquery(md) {
			width: 70%;
		}
	}

	&__map {
		margin-top: 20px;
		height: 500px;
		border-radius: 5px;
		border: 2px solid #aeaeae;
	}
}
</style>
