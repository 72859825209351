export default {
  state: {
    ration: {},
  },
  getters: {
    getRation: state => state.ration,
  },
  mutations: {
    updateRation(state) {
      state.ration = {
        list: [{
            id: 0,
            time: '07:00',
            products: [{
                id: 0,
                product: 'Крупы: Гречневая ядрица',
                weight: 100,
                proteins: 12.6,
                fats: 2.6,
                carbs: 68,
                calories: 329,
              },
              {
                id: 1,
                product: 'Молочные продукты: Масло сливочное',
                weight: 10,
                proteins: 0.1,
                fats: 7.3,
                carbs: 0.1,
                calories: 66.2,
              },
              {
                id: 2,
                product: 'Прочее: Яйцо куриное',
                weight: 50,
                proteins: 6.4,
                fats: 5.8,
                carbs: 0.4,
                calories: 78.5,
              },
              {
                id: 3,
                product: 'Молочные продукты: Сыр тильзитер',
                weight: 25,
                proteins: 5.8,
                fats: 7,
                carbs: 0,
                calories: 86,
              },
              {
                id: 3,
                product: 'Прочее: Майонез',
                weight: 5,
                proteins: 5.8,
                fats: 7,
                carbs: 0,
                calories: 86,
              },
            ],
          },
          {
            id: 1,
            time: '11:00',
            products: [{
                id: 0,
                product: 'Мюсли',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
              {
                id: 1,
                product: 'Йогурт',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
            ],
          },
          {
            id: 2,
            time: '14:00',
            products: [{
                id: 0,
                product: 'Макаронные изделия: Спагетти',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
              {
                id: 1,
                product: 'Прочее: Соевый фарш',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
              {
                id: 2,
                product: 'Мучные изделия: Хлеб бородинский',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
            ],
          },
          {
            id: 3,
            time: '17:00',
            products: [{
                id: 0,
                product: 'Молочные продукты: Молоко',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
              {
                id: 1,
                product: 'Прочее: Гейнер',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
            ],
          },
          {
            id: 4,
            time: '20:30',
            products: [{
                id: 0,
                product: 'Крупы: Рисовая',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
              {
                id: 1,
                product: 'Прочее: Яйцо куриное',
                weight: 100,
                proteins: 12,
                fats: 12,
                carbs: 30,
                calories: 329,
              },
            ],
          },
        ]
      }
    }
  },
  actions: {
    fetchRation(context) {
      context.commit("updateRation");
    },
  }
}