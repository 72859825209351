<template>
	<div class="people">
		<main class="people__main">
			<div class="people__content">
				<h2 class="people__headline">Наши люди</h2>
				<div class="people__intro">
					<div class="people__preface">
						В данном разделе собраны великие русские люди, разделенные по категориям
					</div>
				</div>
				<ul class="people__categories">
					<li class="people__category">
						<font-awesome-icon class="people__category-icon" icon="user-graduate" />
						<span class="people__category-name">Ученые</span>
						<span class="people__category-quantity">23</span>
					</li>
					<li class="people__category">
						<font-awesome-icon class="people__category-icon" icon="dumbbell" />
						<span class="people__category-name">Спортсмены</span>
						<span class="people__category-quantity">12</span>
					</li>
					<li class="people__category">
						<font-awesome-icon class="people__category-icon" icon="lightbulb" />
						<span class="people__category-name">Изобретатели</span>
						<span class="people__category-quantity">45</span>
					</li>
				</ul>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "People"
};
</script>

<style lang="scss" scoped>
.people {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 70%;
	}

	&__categories {
		display: flex;
		justify-content: space-between;
		margin: 20px 0 0 0;
		padding: 0;
		list-style: none;
	}

	&__category {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		border: 2px solid #b0b0b0;
		border-radius: 10px;
		padding: 10px 10px 20px;
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include mediaquery(lg) {
			width: calc(33.333333% - 15px);
			margin: 0;
		}

		&-icon {
			font-size: 50px;
			color: #8c8c8c;
		}

		&-name {
			margin: 15px 0 10px;
			font-size: 20px;
			font-weight: 500;
			color: #8c8c8c;
		}

		&-quantity {
			font-size: 40px;
			font-weight: 500;
			color: #8c8c8c;
		}
	}
}
</style>
