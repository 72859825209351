<template>
	<div class="sport">
		<main class="sport__main">
			<div class="sport__content">
				<h2 class="sport__headline">Спорт</h2>
				<div class="sport__intro">
					<div class="sport__preface">
						В данном разделе собрана информация о тренировках, проводящихся активистами Русской Общины для
						популяризации ЗОЖ. Вы можете предложить свой вариант тренировок, нажав на кнопку "Предложить".
					</div>
					<button class="sport__request">Предложить</button>
				</div>
				<div>
					<h3 class="sport__caption">Действующие тренировки</h3>
				</div>
				<ul class="sport__list">
					<li class="sport__item">
						<div class="sport__item-name" data-id="1">Клуб "Альфа"</div>
						<div class="sport__item-address">Екатеринбург, ул. Розы Люксембург, 34</div>
						<div class="sport__item-timetable">
							<p class="sport__item-paragraph">Расписание</p>
							<p class="sport__item-paragraph">Вт, Чт с 20:00 до 21:00</p>
						</div>
						<div class="sport__item-price">Цена: бесплатно</div>
					</li>
					<li class="sport__item">
						<div class="sport__item-name" data-id="2">Клуб бокса "Русь"</div>
						<div class="sport__item-address">Екатеринбург, ул. Билимбаевская, 22</div>
						<div class="sport__item-timetable">
							<p class="sport__item-paragraph">Расписание</p>
							<p class="sport__item-paragraph">Пн, Ср, Пт с 20:30 до 22:00</p>
						</div>
						<div class="sport__item-price">Цена: бесплатно</div>
					</li>
					<li class="sport__item">
						<div class="sport__item-name" data-id="3">Клуб "Самурай"</div>
						<div class="sport__item-address">Екатеринбург, ул. Чкалова, 239</div>
						<div class="sport__item-timetable">
							<p class="sport__item-paragraph">Расписание</p>
							<p class="sport__item-paragraph">Пн, Пт с 21:15 до 22:30</p>
						</div>
						<div class="sport__item-price">Цена: общая сумма 600 рублей</div>
					</li>
					<li class="sport__item">
						<div class="sport__item-name" data-id="4">СК "Дозор"</div>
						<div class="sport__item-address">Екатеринбург, ул. Мира, 2</div>
						<div class="sport__item-timetable">
							<p class="sport__item-paragraph">Расписание</p>
							<p class="sport__item-paragraph">Пн, Ср, Пт с 20:00 до 21:30</p>
						</div>
						<div class="sport__item-price">Цена: бесплатно</div>
					</li>
				</ul>
				<div class="sport__map" id="map"></div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Sport",
	created() {
		let DG = require("2gis-maps");
		let map = document.querySelector("#map");

		let objects = [
			{
				latitude: 56.831339,
				longitude: 60.612836,
				name: 'Клуб "Альфа"',
				address: "Екатеринбург, ул. Розы Люксембург, 34"
			},
			{
				latitude: 56.884355,
				longitude: 60.513608,
				name: 'Клуб бокса "Русь"',
				address: "Екатеринбург, ул. Билимбаевская, 22"
			},
			{
				latitude: 56.78387,
				longitude: 60.541986,
				name: 'Клуб "Самурай"',
				address: "Екатеринбург, ул. Чкалова, 239"
			},
			{
				latitude: 56.852696,
				longitude: 60.646064,
				name: 'СК "Дозор"',
				address: "Екатеринбург, ул. Мира, 2"
			}
		];

		DG.then(function() {
			map = DG.map("map", {
				center: [56.837293, 60.597807],
				zoom: 11
			});

			let markers = DG.featureGroup();

			for (let i = 0; i < objects.length; i++) {
				let icon = DG.icon({
					iconUrl: require("../assets/images/location.svg"),
					iconSize: [30, "auto"],
					iconAnchor: [objects[i].latitude - 41, objects[i].longitude - 30]
				});

				DG.marker([objects[i].latitude, objects[i].longitude], {
					icon: icon
				})
					.addTo(map)
					.bindPopup(`${objects[i].name} <br /> ${objects[i].address}`);
			}

			markers.addTo(map);
		});
	}
};
</script>

<style lang="scss" scoped>
.sport {
	width: 100%;

	&__main {
		@include main;
	}

	&__content {
		@include content;
		width: 100%;
	}

	&__headline {
		@include headline;
	}

	&__intro {
		display: flex;
		flex-direction: column;

		@include mediaquery(md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__preface {
		color: #8c8c8c;
		font-size: 16px;
		width: 100%;
		margin-bottom: 20px;

		@include mediaquery(md) {
			width: 70%;
			margin-bottom: 0;
		}
	}

	&__request {
		background: linear-gradient(244deg, #318efc -26.75%, #1160bd 106.97%, #196ed2 106.97%);
		border: 0;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		padding: 10px;
		width: 100%;
		cursor: pointer;

		@include mediaquery(md) {
			width: 110px;
		}
	}

	&__caption {
		font-weight: 500;
		color: #8c8c8c;
		margin-bottom: 5px;
	}

	&__list {
		margin: 20px 0 20px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		font-size: 14px;
		border-radius: 5px;
		border: 2px solid #aeaeae;
		padding: 10px;
		width: 100%;
		margin-bottom: 10px;

		@include mediaquery(md) {
			width: calc(25% - 20px);
			margin-bottom: 0;
			margin-right: 20px;

			&:last-of-type {
				margin-right: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&-name {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 10px;
		}

		&-address {
			text-decoration: underline;

			&:hover {
				color: #1768c7;
				cursor: pointer;
			}
		}

		&-timetable {
			font-weight: 500;
			margin-top: 10px;
		}

		&-paragraph {
			margin: 0 0 5px;
		}

		&-price {
			color: #f47fa2;
		}
	}

	&__map {
		height: 500px;
		border-radius: 5px;
		border: 2px solid #aeaeae;
	}
}
</style>
