<template>
  <div class="nothing">
    <main class="nothing__main">
      <div class="nothing__content">
        <h2 class="nothing__headline">Несуществующая страница</h2>
        <div class="nothing__text">
          Кажется что-то пошло не так! Запрашиваемая вами страница не
          существует. Возможно она устарела, была удалена или был введен
          неверный адрес в адресной строке браузера.
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    name: "Nothing",
    components: {},
  };
</script>

<style lang="scss" scoped>
  .nothing {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
    }

    &__headline {
      @include headline;
    }

    &__text {
      font-size: 16px;
    }
  }
</style>