export default {
  state: {
    product: {},
  },
  getters: {
    getProduct: state => state.product,
  },
  mutations: {
    updateProduct(state) {
      state.product = {
        name: 'Яйцо куриное',
        list: [{
            id: 0,
            nutrient: 'Белки',
            quantity: 12.7,
            norm: 76,
            gram: 16.7,
            kkal: 10.6,
            perc: 598,
          },
          {
            id: 1,
            nutrient: 'Жиры',
            quantity: 11.5,
            norm: 56,
            gram: 20.5,
            kkal: 13.1,
            perc: 487,
          },
          {
            id: 2,
            nutrient: 'Углеводы',
            quantity: 0.7,
            norm: 219,
            gram: 0.3,
            kkal: 0.2,
            perc: 31286,
          },
        ],
        chart: {
          series: [12.7, 11.5, 0.7],
          options: {
            labels: ["Белки", "Жиры", "Углеводы"],
            colors: ["#186b95", "#FF4500", "#C71585"],
          },
        }
      }
    }
  },
  actions: {
    fetchProduct(context) {
      context.commit("updateProduct");
    },
  }
}