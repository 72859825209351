<template>
  <div class="culture">
    <main class="culture__main">
      <div class="culture__content">
        <h2 class="culture__headline">Наша культура</h2>
        <ul class="culture__list">
          <li class="culture__item">
            <img class="culture__item-image" src="https://cso-simferopol.ru/wp-content/uploads/2022/06/russkie-narodnye-pesni.png" alt="">
            <div class="culture__item-info">
              <div class="culture__item-title">Русские народные песни</div>
              <div class="culture__item-description">
                Русская народная песня — фольклорное произведение, которое сохраняется в народной памяти и передаётся в устной форме, продукт коллективного устного творчества русского народа. Относится к народному искусству.
              </div>
            </div>
            <div class="culture__item-more">Подробнее</div>
          </li>
          <li class="culture__item">
            <img class="culture__item-image" src="https://meridiancentre.ru/upload/iblock/70f/70f250e128fff48bddfd624b1f071abc.jpg" alt="">
            <div class="culture__item-info">
              <div class="culture__item-title">Русские народные песни</div>
              <div class="culture__item-description">
                Русский народный танец — русское народное танцевальное искусство, представленное в виде народного самодеятельного или постановочного сценического танца. Для мужской пляски характерны удаль, широта души, юмор; женский танец отличается плавностью, величавостью, лёгким кокетством. Русские танцы являются неотъемлемой частью русской национальной культуры.
              </div>
            </div>
            <div class="culture__item-more">Подробнее</div>
          </li>
          <li class="culture__item">
            <img class="culture__item-image" src="https://sok-t.ru/wp-content/uploads/2020/06/s1200-2.jpg" alt="">
            <div class="culture__item-info">
              <div class="culture__item-title">Русские народные музыкальнык инструменты</div>
              <div class="culture__item-description">
                Русские народные музыкальные инструменты — самобытные инструменты русских крестьян, выражающие особые специфические черты нации. В своём развитии связаны с духовной жизнью, бытовым укладом, эстетическими и нравственными устоями русского народа.
              </div>
            </div>
            <div class="culture__item-more">Подробнее</div>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    name: "Culture",
  };
</script>

<style lang="scss" scoped>
  .culture {
    width: 100%;

    &__main {
      @include main;
    }

    &__content {
      @include content;
      width: 100%;
    }

    &__headline {
      @include headline;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: -10px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border: 2px solid #AEAEAE;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
      width: calc(50% - 20px);

      &:last-of-type {
        margin-bottom: 0;
      }

      &-image {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 2px solid #AEAEAE;
        border-radius: 10px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin: 0 10px;
      }

      &-title {
        font-weight: 500;
        color: #8C8C8C;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &-more {
        background: linear-gradient(244deg, #318EFC -26.75%, #1160BD 106.97%, #196ED2 106.97%);
        border: 0;
        border-radius: 10px;
        color: #FFF;
        font-size: 16px;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
</style>